import React from "react";
import "./SectionForce.css";
import cfapolice from "../../../../Assets/ressources/catalogue/Vtravail/cforce/police.jpg";
import cfagendarme from "../../../../Assets/ressources/catalogue/Vtravail/cforce/gendarme.jpg";
import cfamilitaire from "../../../../Assets/ressources/catalogue/Vtravail/cforce/militaire.jpg";
import cfasecurite from "../../../../Assets/ressources/catalogue/Vtravail/cforce/securite.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SectionForce = () => {

    return(

        <div className="container-fluid SectionForce">

            <div className="container">

                <div className="row">

                    {/*Titre collection*/}
                    <div className="col-12 mt-5">

                        <div className="d-flex">

                            <p className="h2 bold600">Collection force et armée</p>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="45" height="45" 
                                viewBox="0 0 24 24"
                                className="iconTextCm"
                            >
                                <path fill="#0047AB" d="M9.93 14.6L12 13l2.02 1.562l-.782-2.516l2.147-1.661H12.83L12 7.83l-.83 2.554H8.615l2.096 1.661zM12 20.962q-3.014-.895-5.007-3.651T5 11.1V5.692l7-2.615l7 2.615V11.1q0 3.454-1.993 6.21T12 20.963m0-1.062q2.6-.825 4.3-3.3t1.7-5.5V6.375l-6-2.23l-6 2.23V11.1q0 3.025 1.7 5.5t4.3 3.3m0-7.88"/>
                            </svg>

                        </div>

                    </div>

                    {/*Carte*/}
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={100}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        className="container-fluid mt-5 swiper-custom"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024:{
                                slidesPerView: 2,
                                spaceBetween: 100,
                            },
                            1440:{
                                slidesPerView: 3,
                                spaceBetween: 100,
                            }
                        }}
                    >

                        {/*Produit force 1*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cfapolice} alt="police" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cfapolice} alt="police" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu mis à disposition par le client.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin"> Poches multiples et fermetures sécurisées pour un accès rapide aux équipements.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation disponible</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Conçue pour s'ajuster parfaitement aux besoins opérationnels</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit force 2*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cfagendarme} alt="police" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cfagendarme} alt="kimono" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu mis à disposition par le client.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin"> Poches multiples et fermetures sécurisées pour un accès rapide aux équipements.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation disponible</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Conçue pour s'ajuster parfaitement aux besoins opérationnels</p>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit force 3*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cfamilitaire} alt="militaire" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cfamilitaire} alt="militaire" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu mis à disposition par le client.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin"> Poches multiples et fermetures sécurisées pour un accès rapide aux équipements.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation disponible</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Conçue pour s'ajuster parfaitement aux besoins opérationnels</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit force 4*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cfasecurite} alt="securité" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cfasecurite} alt="sécurité" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu mis à disposition par le client.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin"> Poches multiples et fermetures sécurisées pour un accès rapide aux équipements.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation disponible</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Conçue pour s'ajuster parfaitement aux besoins opérationnels</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                    </Swiper>

                </div>

            </div>

        </div>

    );

}

export default SectionForce;