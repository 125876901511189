import React, { useEffect } from "react";
import "./CatalogueAccessoire.css";
import imgAccess from "../../../Assets/ressources/sections/casquerouge.jpg";
import SectionMedicaleC from "./SectionMedicaleC/SectionMedicaleC";
import SectionEsthetiqueC from "./SectionEsthetiqueC/SectionEsthetiqueC";
import SectionHotelC from "./SectionHotelC/SectionHotelC";
import SectionOfficeC from "./SectionOfficeC/SectionOfficeC";
import SectionIndustrielleC from "./SectionIndustrielleC/SectionIndustrielleC";
import SectionForceC from "./SectionForceC/SectionForceC";
import SectionPluieC from "./SectionPluieC/SectionPluieC";

const CatalogueAccessoire = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div className="CatalogueAccessoire">

            {/*Image Entete*/}
            <div className="w-100">

            {/*IMAGE*/}
            <img src={imgAccess} alt="casque" className="img-fluidCatalogue"/>

            {/*TEXTE*/}
            <p className="display-3 text-center text-white textDisplay container">Accessoires</p>

            </div>

            {/*Liste des collections des accessoires*/}
            <SectionIndustrielleC></SectionIndustrielleC>
            <SectionMedicaleC></SectionMedicaleC>
            <SectionOfficeC></SectionOfficeC>
            <SectionHotelC></SectionHotelC>
            <SectionForceC></SectionForceC>
            <SectionEsthetiqueC></SectionEsthetiqueC>
            <SectionPluieC></SectionPluieC>
           

        </div>

    );

}

export default CatalogueAccessoire;