import React, { useState } from "react";
import "./Footer.css";
import logoTita from "../../Assets/ressources/logo/logoA.jpg";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {

    const location = useLocation();

    const isAccueil = location.pathname === '/';
    const isProduits = location.pathname === '/produits';
    const isCatalogueS = location.pathname === '/cataloguesecurite';
    const isCatalogueT = location.pathname === '/cataloguetravail';
    const isCatalogueA = location.pathname === '/catalogueaccessoire';
    const isPersonnalisation = location.pathname === '/personnalisation';
    const isLivraison = location.pathname === '/livraison';
    const isNous = location.pathname === '/nous';
    const isContact = location.pathname === '/contact';
    const isCoIndustrielle = location.pathname === '/collectionindustrielle';
    const isCoMedicale = location.pathname === '/collectionmedicale';
    const isCoHotel = location.pathname === '/collectionhotel';
    const isCoOffice= location.pathname === '/collectionoffice';
    const isCoForce = location.pathname === '/collectionforce';
    const isCoEsthetique = location.pathname === '/collectionesthetique';
    const isCoPluie = location.pathname === '/collectionpluie';
    const isCoPorter = location.pathname === '/pretaporter';
    const isPolitique = location.pathname === '/politiqueconfidentialite';
    const isConditions = location.pathname === '/conditiondevente';
    const isFAQ = location.pathname === '/faq';
    const isMentionLegale = location.pathname === '/mentionlegale';

    return(
        
        <footer className={

            isAccueil ? 'footerAccueil': 
            isProduits ? 'footerProduits': 
            isCatalogueS ? 'footerCatalogueS':
            isCatalogueT ? 'footerCatalogueS':
            isCatalogueA ? 'footerCatalogueS':
            isPersonnalisation ? 'footerPersonnalisation':
            isLivraison ? 'footerLivraison':
            isContact ? 'footerContact':
            isNous ? 'footerNous': 
            isCoIndustrielle ? 'footerCatalogueS':
            isCoMedicale ? 'footerCatalogueS':
            isCoHotel ? 'footerCatalogueS':
            isCoOffice ? 'footerCatalogueS':
            isCoForce ? 'footerCatalogueS':
            isCoEsthetique ? 'footerCatalogueS' :
            isCoPluie ? 'footerCatalogueS' :
            isCoPorter ? 'footerCatalogueP' :
            isPolitique ? 'footerPolitique' :
            isConditions ? 'footerPolitique' :
            isFAQ ? 'footerFAQ':
            isMentionLegale ? 'footerPolitique': ''
            
        }>

            <div className="container">

                {/*INFORMATIONS*/}
                <div className="container">

                    <div className="row">

                        {/*AUTRES LIENS FOOTER*/}
                        <div className="col-12 mt-4">

                            <div className="row">

                                {/*LIENS*/}
                                <div className="col-12 col-md-6 mb-5">

                                    <div className="d-flex flex-column">

                                        {/*LOGO*/}
                                        <div className="container">

                                            <img src={logoTita} alt="logo" className="img-fluidLogotita " />

                                        </div>

                                        <div className="container">
                                            <NavLink to="/politiqueconfidentialite" className="linkFooter text-white fs12 bold500">Politique de confidentialité</NavLink>
                                        </div>
                                        
                                        <div className="container">
                                            <NavLink to="/conditiondevente" className="linkFooter text-white fs12 bold500">Conditions générales de vente</NavLink>
                                        </div>
                                        
                                        <div className="container">
                                            <NavLink to="/faq" className="linkFooter text-white fs12 bold500">FAQ</NavLink>
                                        </div>

                                        <div className="container">
                                            <NavLink to="/mentionlegale" className="linkFooter text-white fs12 bold500">Mentions légales</NavLink>
                                        </div>

                                    </div>

                                </div>
                                
                                {/*RESEAUX SOCIAUX*/}
                                <div className="col-12 col-md-6 mt-3">

                                    <p className="h4 text-white text-center mb-4">Nos réseaux sociaux</p>

                                    {/*Les liens*/}
                                    <div className=" container d-flex justify-content-around mb-5">

                                        {/*Facebook*/}
                                        <a href="https://www.facebook.com/titanium22voushabille" target="_blank" rel="noopener">
                                        
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                width="60" height="60" 
                                                viewBox="0 0 24 24"
                                            >
                                                <path fill="#fff" d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95"/>
                                            </svg>

                                        </a>
                                        
                                        {/*Linkedin*/}
                                        <a href="https://www.linkedin.com/company/lamba-malagasy/" target="_blank" rel="noopener">
                                        
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                width="60" height="60" 
                                                viewBox="0 0 24 24"
                                            >
                                                <path fill="#fff" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"/>
                                            </svg>

                                        </a>

                                        {/*Instagram*/}
                                        <a href="https://www.instagram.com/titanium22_voushabille/" target="_blank" rel="noopener">
                                        
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="60" height="60" 
                                            viewBox="0 0 24 24"
                                        >
                                            <path fill="#fff" d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"/>
                                        </svg>

                                        </a>

                                    </div>
                                    
                                    {/*Information supplémentaire*/}
                                    <div className="container d-flex justify-content-center align-items-center gapForInfo flex-column-sm">

                                        {/*EMAIL*/}
                                        <div className="marginForEmail">
                                            
                                            <div className="container d-flex flex-column text-center">

                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" className="text-center"><path fill="#fff" d="M4.616 19q-.691 0-1.153-.462T3 17.384V6.616q0-.691.463-1.153T4.615 5h14.77q.69 0 1.152.463T21 6.616v10.769q0 .69-.463 1.153T19.385 19zM12 12.116L4 6.885v10.5q0 .269.173.442t.443.173h14.769q.269 0 .442-.173t.173-.443v-10.5zM12 11l7.692-5H4.308zM4 6.885V6v11.385q0 .269.173.442t.443.173H4z"/></svg>
                                                </div>
                                                
                                                <p className="text-white bold400 fs12">acom.titanium22@groupeuser.mg</p>
                                                <p className="text-white bold400 fs12">titanium22@groupeuser.mg</p>

                                            </div>

                                        </div>

                                        {/*Numéro*/}
                                        <div className="marginForNumero">

                                            <div className="container d-flex flex-column text-center">

                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24"><path fill="#fff" d="M18.2 21q-2.702 0-5.418-1.244t-4.995-3.533t-3.533-5.021Q3 8.469 3 5.8V5h4.439l.848 4.083l-2.697 2.51q.685 1.186 1.418 2.167t1.527 1.769q.802.84 1.808 1.57t2.296 1.44l2.611-2.708l3.75.756V21zm-1.697-9q-1.867 0-3.185-1.315Q12 9.369 12 7.502t1.316-3.185T16.497 3q1.867 0 3.185 1.316Q21 5.63 21 7.497t-1.315 3.185T16.503 12m-.387-1.616h.769V6.808h-.77zM16 5.692h1v-1h-1z"/></svg>
                                                </div>
                                                    
                                                <p className="text-white bold400 fs12">+261326534483</p>
                                                <p className="text-white bold400 fs12">+261343352722</p>

                                            </div>

                                        </div>
                                        
                                    </div>

                                </div>

                            </div>

                        </div>

                        {/*Titanium22 copyright*/}
                        <div className=" container col-12">

                            <p className="text-center fs12 bold600 text-white mt-5">© Titanium22 2024</p>

                        </div>

                    </div>

                </div>

            </div>

        </footer>

    );

}

export default Footer;