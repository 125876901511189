import React from "react";
import "./SectionEquipe.css";
import equipe1 from "../../../Assets/ressources/photos/P3.jpg";
import equipe2 from "../../../Assets/ressources/photos/P4.jpg";
import equipe3 from "../../../Assets/ressources/photos/P5.jpg";
import equipe4 from "../../../Assets/ressources/photos/P7.jpg";
import equipe5 from "../../../Assets/ressources/photos/P9.jpg";
import equipe6 from "../../../Assets/ressources/photos/P11.jpg";
import equipe7 from "../../../Assets/ressources/photos/P14.jpg";
import equipe8 from "../../../Assets/ressources/photos/P15.jpg";
import equipe9 from "../../../Assets/ressources/photos/P19.jpg";
import equipe10 from "../../../Assets/ressources/photos/P20.jpg";
import equipe11 from "../../../Assets/ressources/photos/P22.jpg";
import equipe12 from "../../../Assets/ressources/photos/P23.jpg";
import equipe13 from "../../../Assets/ressources/photos/equipe1.jpg";
import equipe14 from "../../../Assets/ressources/photos/equipe2.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const SectionEquipe = () => {

    return(

        <div>

            <p className="container equipeText text-white">Notre équipe</p>

            {/*Carousel notre équipe*/}
            <div className="w-100 equipeCarousel">

                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    slidesPerView={1}
                    autoplay={{delay: 2000}}
                    loop={true}
                    rewind={false}
                    speed={1000}
                    className="container-fluid rounded-4"
                >   

                    <SwiperSlide>

                        <img src={equipe1} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe2} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe3} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe4} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe5} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe6} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe7} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe8} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe9} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe10} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe11} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe12} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe13} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>

                    <SwiperSlide>

                        <img src={equipe14} alt="equipe" className="d-block w-100 border img-s1" />

                    </SwiperSlide>
                                  

                </Swiper>

            </div>

        </div>

    );
    
}

export default SectionEquipe;