import React, { useEffect } from "react";
import "./CataloguePretPorter.css";
import imgPorter from "../../../Assets/ressources/sections/femmeplage.jpg";
import SectionPretaPorter from "./SectionPretaPorter/SectionPretaPorter";

const CataloguePretPorter = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div className="CataloguePretPorter">

            {/*Image Entete*/}
            <div className="w-100">

                {/*IMAGE*/}
                <img src={imgPorter} alt="office" className="img-fluidCatalogue"/>
                
                {/*TEXTE*/}
                <p className="display-3 text-center text-white textDisplay container">Prêt-à-porter</p>
                
            </div>

            <SectionPretaPorter></SectionPretaPorter>

        </div>

    );

}

export default CataloguePretPorter;