import React, { useEffect } from "react";
import "./PolitiqueConfi.css"

const PolitiqueConfi = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div className="container PolitiqueConfi">
            
            <p className="h1 text-center py-5">Politique de confidentialité</p>

            <div className="container d-flex flex-column">

                <p className="h2 my-3 poliTitle">Introduction</p>
                <p className="fs14 bold500">Notre société, Titanium22, s'engage à protéger votre vie privée et à garantir la confidentialité des informations personnelles que vous nous confiez.</p>
                
                <p className="h2 my-3 poliTitle">Données Collectées :</p>
                <p className="fs14 bold500">Nous collectons les types de données suivants : <br/>  <span className="bold600">Informations personnelles : nom, adresse, email, numéro de téléphone</span> <br/> <span className="bold600">Données de navigation : adresse IP, type de navigateur, pages visitées.</span></p>

                <p className="h2 my-3 poliTitle">Utilisation des Données :</p>
                <p className="fs14 bold500">Les données collectées sont utilisées pour : <br/> <span className="bold600">Traiter et livrer vos commandes <br/> Vous informer sur nos produits et offres promotionnelles</span>  <br/> <span className="bold600">Améliorer notre site web et nos services.</span></p>

                <p className="h2 my-3 poliTitle">Partage des Données :</p>
                <p className="fs14 bold500">Nous partageons vos données avec : <span className="bold600"> <br/> Nos partenaires logistiques pour la livraison de vos commandes. <br/> Nos prestataires de paiement pour le traitement des transactions.</span></p>

                <p className="h2 my-3 poliTitle">Droits des Utilisateurs :</p>
                <p className="fs14 bold500">Vous avez le droit de : <span className="bold600"> <br/> Accéder à vos données personnelles. <br/> Demander la rectification ou la suppression de vos données. <br/> Vous opposer au traitement de vos données.</span></p>

                <p className="h2 my-3 poliTitle">Sécurité des Données :</p>
                <p className="fs14 bold500">Nous utilisons des mesures de sécurité telles que le chiffrement des données et des accès restreints pour protéger vos informations personnelles.</p>

                <p className="h2 my-3 poliTitle">Modifications de la Politique :</p>
                <p className="fs14 bold500 mb-5">Nous nous réservons le droit de modifier cette politique de confidentialité. Toute modification sera publiée sur cette page.</p>

            </div>

        </div>

    );

}

export default PolitiqueConfi;