import React from "react";
import "./SectionPretPorter.css";
import hommeporter from "../../../Assets/ressources/sections/hommeporter.jpg";
import femmeporter from "../../../Assets/ressources/sections/femmeporter.jpg";
import shirt from "../../../Assets/ressources/logo/shirt.jpg";
import { NavLink } from "react-router-dom";

const SectionPretPorter = () => {

    return(

        <div className="container-fluid SectionPretPorter">

            <div className="container">

                {/*CONTENT*/}
                <div className="container row catprodText">

                    {/*TEXTE*/}
                    <div className="col-12 col-md-6 col-lg-5">
                    
                        <div className="row">

                            {/*GRAND TITRE*/}
                            <div className="col-12 mb-2">

                                <div className="d-flex">

                                    <p className="h1 bold600">Prêt-à-porter</p>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="55" height="55" 
                                        viewBox="0 0 24 24"
                                        className="iconTextCm"
                                    >
                                        <path fill="#0047AB" d="m7 9.277l-1.789.992q-.292.162-.577.09q-.284-.07-.446-.363l-1.23-2.154q-.162-.292-.09-.586q.07-.295.363-.456L7.98 4h1.211q.283 0 .45.166q.166.167.166.45v.192q0 .882.655 1.537T12 7t1.538-.655t.654-1.537v-.192q0-.283.167-.45q.166-.166.449-.166h1.211l4.75 2.8q.293.162.364.456q.07.294-.09.586l-1.231 2.154q-.162.292-.444.36t-.58-.092L17 9.283v9.948q0 .328-.22.549t-.55.22H7.77q-.33 0-.55-.22T7 19.23zM8 7.6V19h8V7.6l3.062 1.68l1.011-1.73l-4.28-2.538h-.635q-.087 1.255-.98 2.122Q13.285 8 12 8t-2.178-.866t-.98-2.123h-.634L3.927 7.55l1.012 1.73zm4 4.406"/>
                                    </svg>

                                </div>

                            </div>

                            {/*DESCRIPTION*/}
                            <div className="col-12">
                                <p className="fs14 bold500">Titanium22 vous propose une gamme raffinée de vêtements prêt-à-porter, alliant confort et élégance. Chaque pièce est soigneusement sélectionnée pour offrir un style moderne tout en garantissant une coupe parfaite.</p>
                            </div>

                            {/*BOUTON*/}
                            <div className="col-12 mt-3">
                                <NavLink to="/pretaporter" className="btnPerso">Voir nos produits</NavLink>
                            </div>

                        </div>

                    </div>

                    {/*IMAGES*/}
                    <div className="col-12 col-md-6 col-lg-7">

                        <div className="container">

                            <div className="d-flex flex-column mfordoubleimages">

                                {/*IMAGE1*/}
                                <div className="container wforvetement">
                                    <img src={femmeporter} alt="femme" className="img-fluidforvetement" />
                                </div>

                                {/*IMAGE2*/}
                                <div className="container wforvetement transformvetement2 d-none d-lg-block">
                                    <img src={hommeporter} alt="femme" className="img-fluidforvetement" />
                                </div>

                            </div>          

                        </div>

                    </div>

                </div>

                
                {/*ICONE CASQUE BACKGROUND en XXL*/}
                <div className="container d-none d-xxl-block">
                    <div className="bgsafe1porter bgcasquetaille">
                        <img src={shirt} alt="casque" className="img-fluid"/>
                    </div>
                    <div className="bgsafe2 bgcasquetaille">
                        <img src={shirt} alt="casque" className="img-fluid"/>
                    </div>
                    <div className="bgsafe3 bgcasquetaille">
                        <img src={shirt} alt="casque" className="img-fluid"/>
                    </div>
                    <div className="bgsafe4 bgcasquetaille">
                        <img src={shirt} alt="casque" className="img-fluid"/>
                    </div>
                </div>

            </div>

        </div>

    );

}

export default SectionPretPorter;