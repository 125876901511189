import React from "react";
import "./SectionTemoignage.css";
import temoin1 from "../../../Assets/ressources/marque/Ulys.jpg";
import temoin2 from "../../../Assets/ressources/marque/materauto.jpg";
import temoin3 from "../../../Assets/ressources/marque/numen.jpg";
import temoin4 from "../../../Assets/ressources/marque/refrigepeche.jpg";
import temoin5 from "../../../Assets/ressources/marque/centell.jpg";
import bgcomment from "../../../Assets/ressources/logo/comment.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';


const SectionTemoignage = () => {

        return(

        <div className="container-fluid SectionTemoignage">
            
            {/*SECTION GENERALE TEMOIGNAGE*/}
            <div className="container">

                {/*TITRE PRESENTATION*/}
                <p className="h1 text-center bold600 catprodText">Ce que les clients disent de nous</p>
                

                <Swiper
                
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={3}
                    autoplay={{delay: 3000}}
                    loop={true}
                    speed={8000}
                    className="container text-center mt-3 listTemoin"
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024:{
                            slidesPerView: 2,
                            spaceBetween: 100,
                        },
                        1440:{
                            slidesPerView: 3,
                            spaceBetween: 100,
                        }
                }} 

                >

                    <SwiperSlide>

                        <div className="container row">

                            {/*PROFIL DU TEMOIN*/}
                            <div className="container col-12 imgCircle">
                                <img src={temoin1} alt="femme" className="img-fluidCircle"/>
                            </div>

                            {/*SON TEMOIGNAGE*/}
                            <div className="container col-12 cardTemoin">
                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5">
                                        Le produit répond bien à mes besoins et le service client a été réactif et utile. Quelques petites améliorations pourraient être apportées, mais dans l'ensemble, je suis content.
                                    </p>
                                    <p className="text-white mt-5 mx-5">ULYS</p>
                                </div>
                            </div>

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                    <div className="container row">
                                
                        {/*PROFIL DU TEMOIN*/}
                        <div className="container col-12 imgCircle">
                            <img src={temoin2} alt="homme" className="img-fluidCircle" />
                        </div>

                        {/*SON TEMOIGNAGE*/}
                        <div className="container col-12 cardTemoin">
                            <div className="d-flex flex-column align-items-center">
                                <p className="text-white text-center mt-5">
                                    Nous sommes heureux de vous informer que votre société a obtenu une évaluation globalement positive avec une moyenne de 3,42/4 pour l'ensemble des critères d'évaluation.  Vos produits/services ont été jugés conformes à nos attentes en termes de qualité et de spécifications.
                                </p>
                                <p className="text-white mt-5 text-end mx-5 moveMaterauto">MATERAUTO</p>
                            </div>
                        </div>  

                    </div>

                    </SwiperSlide>

                    <SwiperSlide>

                    <div className="container row">
                                    
                        {/*PROFIL DU TEMOIN*/}
                        <div className="container col-12 imgCircle">
                            <img src={temoin3} alt="homme" className="img-fluidCircle" />
                        </div>

                        {/*SON TEMOIGNAGE*/}
                        <div className="container col-12 cardTemoin">
                            <div className="d-flex flex-column align-items-center">
                                <p className="text-white text-center mt-5">
                                    Travailler avec vous est une expérience très positive, la communication est excellente, le travail est de qualité et la ponctualité est excellente, mais il y a des améliorations à apporter.
                                </p>
                                <p className="text-white mt-5 text-end mx-5">NUMEN</p>
                            </div>
                        </div>  

                    </div>
    
                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">
                                        
                            {/*PROFIL DU TEMOIN*/}
                            <div className="container col-12 imgCircleRefrigepeche">
                                <img src={temoin4} alt="homme" className="img-fluidCircle" />
                            </div>

                            {/*SON TEMOIGNAGE*/}
                            <div className="container col-12 cardTemoin">
                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5">
                                        J’ai été impressionné par la qualité du produit et la rapidité du service. Continuez votre excellent travail !
                                    </p>
                                    <p className="text-white mt-5 text-end mx-5 moveRefrigepeche">REFRIGEPECHE</p>
                                </div>
                            </div>  

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">
                                        
                            {/*PROFIL DU TEMOIN*/}
                            <div className="container col-12 imgCircle">
                                <img src={temoin5} alt="homme" className="img-fluidCircle" />
                            </div>

                            {/*SON TEMOIGNAGE*/}
                            <div className="container col-12 cardTemoin">
                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5">
                                        J’apprécie vraiment le suivi post-achat que vous offrez. C’est rassurant de savoir que vous êtes là en cas de besoin.
                                    </p>
                                    <p className="text-white mt-5 text-end mx-5 moveCentell">LE CENTELL</p>
                                </div>
                            </div>  

                        </div>

                    </SwiperSlide>

                </Swiper>

            </div>

            {/*ICONE BACKGROUND EN XXL*/}
            <div className="container d-none d-xxl-block">

                <div className="bgcomment1">
                    <img src={bgcomment} alt="comment" className="img-fluid"/>
                </div>
                
                <div className="bgcomment2">
                    <img src={bgcomment} alt="comment" className="img-fluid"/>
                </div>
                
                <div className="bgcomment3">
                    <img src={bgcomment} alt="comment" className="img-fluid"/>
                </div>

            </div>

        </div>

    );

}

export default SectionTemoignage;