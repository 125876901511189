import React, { useEffect } from "react";
import HeaderLivraison from "./HeaderLivraison/HeaderLivraison";
import SectionTarif from "./SectionTarif/SectionTarif";
import SectionSav from "./SectionSav/SectionSav";

const Livraison = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div>
            <HeaderLivraison></HeaderLivraison>
            <SectionTarif></SectionTarif>
            <SectionSav></SectionSav>
        </div>

    );

}

export default Livraison;