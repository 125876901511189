import React from "react";
import "./SectionEsthetique.css";
import cekimono from "../../../../Assets/ressources/catalogue/Vtravail/cesthetique/kimono.jpg";
import cemao from "../../../../Assets/ressources/catalogue/Vtravail/cesthetique/mao.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SectionEsthetique = () => {

    return(

        <div className="container-fluid SectionEsthetique">

            <div className="container">

                <div className="row">

                    {/*Titre collection*/}
                    <div className="col-12 mt-5">

                        <div className="d-flex">

                            <p className="h2 bold600">Collection bien-être / esthétique</p>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="45" height="45"
                                viewBox="0 0 24 24"
                                className="iconTextCm"
                            >
                                <path fill="#0047AB" d="M11.266 19.808q-.225-.655-.575-1.209t-.825-1.032t-1.025-.832t-1.2-.577q.05.725.35 1.35t.8 1.125t1.125.803t1.35.372m1.45 0q.725-.056 1.355-.356t1.134-.8t.805-1.135t.356-1.36q-.646.226-1.204.576t-1.03.825t-.83 1.037t-.586 1.213M12 11.577q1.592 0 2.7-1.108t1.108-2.7V5.992l-1.75 1.475L12 4.742L9.942 7.467l-1.75-1.475V7.77q0 1.593 1.108 2.7t2.7 1.108m-.385 9.23q-2.083 0-3.541-1.458q-1.459-1.458-1.459-3.541v-.885q1.583.244 2.889 1.138T11.5 18.25v-5.69q-1.823-.177-3.065-1.542Q7.192 9.654 7.192 7.77V5.561q0-.534.48-.739q.478-.205.863.143l1.273 1.1l1.551-2.042q.25-.312.644-.312q.395 0 .637.312l1.552 2.042l1.273-1.1q.385-.348.864-.143t.479.74v2.207q0 1.885-1.243 3.25q-1.242 1.364-3.065 1.54v5.672q.69-1.275 1.986-2.17q1.297-.894 2.86-1.138v.885q0 2.083-1.453 3.541q-1.452 1.459-3.528 1.459zm-2.15-2.824"/>
                            </svg>

                        </div>

                    </div>

                    {/*Carte*/}
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={100}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        className="container-fluid mt-5 swiper-custom"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024:{
                                slidesPerView: 2,
                                spaceBetween: 100,
                            },
                            1440:{
                                slidesPerView: 3,
                                spaceBetween: 100,
                            }
                        }}
                    >

                        {/*Produit esthetique 1*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cekimono} alt="kimono" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cekimono} alt="kimono" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles: S, M, L, XL, XXL, 3XL, 4XL.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin"> Tissu drill ou coton pour un confort maximal.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Broderie ou sérigraphie pour votre logo.</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit esthetique 2*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cemao} alt="mao" className="img-fluidCardKimonoNoir"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cemao} alt="mao" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">S, M, L, XL, XXL, 3XL, 4XL.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Drill ou coton pour un confort maximal</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Reproduction personnalisée de votre logo.</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                    </Swiper>

                </div>

            </div>

        </div>

    );

}

export default SectionEsthetique;