import React from "react";
import "./SectionPluieC.css";
import cpbottepluie from "../../../../Assets/ressources/catalogue/Accessoires/cpluie/bottepluie.jpg";
import cpbottepluie2 from "../../../../Assets/ressources/catalogue/Accessoires/cpluie/bottepluie2.jpg";
import cpbottepluie3 from "../../../../Assets/ressources/catalogue/Accessoires/cpluie/bottepluie3.jpg";
import cpbottepluie4 from "../../../../Assets/ressources/catalogue/Accessoires/cpluie/bottepluie4.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SectionPluieC = () => {

    return(

        <div className="container-fluid SectionPluieC">

            <div className="container">

                <div className="row">

                     {/*Titre collection*/}
                     <div className="col-12 mt-5">

                        <div className="d-flex">

                            <p className="h2 bold600">Collection Vêtements de pluie</p>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="55" height="55"
                                viewBox="0 0 24 24"
                                className="iconTextCm"
                            >
                                <path fill="#0047AB" d="M13.816 21.612q-.183.104-.379.033t-.298-.253l-1.693-3.384q-.104-.183-.033-.378t.253-.3t.387-.023t.309.262l1.692 3.366q.104.182.024.378q-.08.195-.262.299m6.019 0q-.183.104-.378.033t-.3-.253l-1.691-3.384q-.104-.183-.034-.378t.253-.3t.387-.023t.309.262l1.692 3.366q.104.182.024.378q-.08.195-.262.299m-12.02 0q-.182.104-.387.033t-.309-.253l-1.692-3.384q-.104-.183-.024-.378t.263-.3t.377-.023t.3.262l1.692 3.366q.104.182.033.378t-.252.299M7.5 15.5q-2.083 0-3.541-1.459Q2.5 12.583 2.5 10.5q0-1.94 1.308-3.356t3.275-1.633q.742-1.405 2.033-2.208Q10.408 2.5 12 2.5q2.154 0 3.701 1.428t1.801 3.559q1.84.111 2.92 1.28Q21.5 9.937 21.5 11.5q0 1.664-1.168 2.832T17.5 15.5zm0-1h10q1.242 0 2.121-.879T20.5 11.5t-.879-2.121T17.5 8.5h-1V8q0-1.861-1.32-3.18T12 3.5q-1.354 0-2.466.737t-1.67 1.97l-.116.293H7.45q-1.636.05-2.793 1.207T3.5 10.5q0 1.662 1.17 2.83T7.5 14.5M12 9"/>
                            </svg>

                        </div>

                    </div>

                    {/*CARTE*/}
                    <Swiper
                    
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={100}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        className="container-fluid mt-5 swiper-custom"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024:{
                                slidesPerView: 2,
                                spaceBetween: 100,
                            },
                            1440:{
                                slidesPerView: 3,
                                spaceBetween: 100,
                            }
                        }}

                    >

                        {/*Produit pluie 1*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cpbottepluie} alt="botte" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cpbottepluie} alt="botte" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">39 - 40 - 41 - 42 - 43 - 44</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Confortables et résistantes</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Matière en PVC</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sans embouts metalliques</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit pluie 2*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cpbottepluie2} alt="botte" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cpbottepluie2} alt="botte" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">39 - 40 - 41 - 42 - 43 - 44</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Confortables et résistantes</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Matière en PVC</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sans embouts metalliques</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit pluie 3*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cpbottepluie3} alt="botte" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cpbottepluie3} alt="botte" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">39 - 40 - 41 - 42 - 43 - 44</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Confortables et résistantes</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Matière en PVC</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sans embouts metalliques</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit pluie 4*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cpbottepluie4} alt="botte" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cpbottepluie4} alt="botte" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">39 - 40 - 41 - 42 - 43 - 44</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Confortables et résistantes</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Matière en PVC</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sans embouts metalliques</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                    </Swiper>
                        

                </div>

            </div>

        </div>

    );

}

export default SectionPluieC;