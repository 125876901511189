import React, { useEffect } from "react";
import HeaderProduits from "./HeaderProduits/HeaderProduits";
import SectionVetSecurite from "./SectionVetSecurite/SectionVetSecurite";
import SectionVetTravail from "./SectionVetTravail/SectionVetTravail";
import SectionAccessoire from "./SectionAccessoire/SectionAccessoire";
import SectionPretPorter from "./SectionPretPorter/SectionPretPorter";

const Produits = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div>
            <HeaderProduits></HeaderProduits>
            <SectionVetTravail></SectionVetTravail>
            <SectionVetSecurite></SectionVetSecurite>
            <SectionAccessoire></SectionAccessoire>
            <SectionPretPorter></SectionPretPorter>
        </div>

    );

}

export default Produits;