import React, { useState } from "react";
import axios from 'axios';
import "./SectionFormulaire.css";
import imgMail from "../../../Assets/animations/enveloppe.jpg";
import Formulaire from "./Formulaire/Formulaire";

const SectionFormulaire = (props:any) => {

    /*Data initial*/
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [societe, setSociete] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event:any) => {
        event.preventDefault(); // Empêche le formulaire de se soumettre automatiquement

        // Créer un objet FormData pour envoyer les données
        const formData = new FormData();
        formData.append('nom: ', nom);
        formData.append('prénom: ', prenom);
        formData.append('adresse email: ', email);
        formData.append('Numéro téléphone: ', phone);
        formData.append('Nom de la société: ', societe);
        formData.append('Messages: ', message);

        // Envoyer les données au serveur Formspree via axios
        axios.post(`https://formspree.io/f/${process.env.REACT_APP_KEY_FORM}`, formData, {
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(response => {
            alert('Merci! Votre message a été envoyé.');
            setNom('');
            setPrenom('')
            setEmail('');
            setPhone('');
            setSociete('');
            setMessage('');
        })
        .catch(error => {
            console.error('Erreur:', error);
            alert('Oops! Une erreur s\'est produite.');
        });
        
    };

    return(

        <div className="container-fluid SectionFormulaire">

            <div className="container">

                <div className="row">

                    {/*FORMULAIRE*/}
                    <div className="col-12 col-md-8 mt-5">
                        <Formulaire 
                            nom={nom} 
                            prenom={prenom} 
                            email={email} 
                            phone={phone} 
                            societe={societe} 
                            message={message} 
                            handleSubmit={handleSubmit} 
                            setNom = {setNom}
                            setPrenom = {setPrenom}
                            setEmail = {setEmail}
                            setPhone = {setPhone}
                            setSociete = {setSociete}
                            setMessage = {setMessage}
                        ></Formulaire>
                    </div>

                    {/*ANIMATION MAIL*/}
                    <div className="col-12 col-md-4 d-none d-md-block">

                        <div className="moveMail">

                            <div className="container">

                                <img src={imgMail} alt="enveloppe" className="img-fluidMail" />

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    );

}

export default SectionFormulaire;