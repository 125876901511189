import React from "react";
import "./SectionFlexibilite.css";
import vestenoir from "../../../Assets/ressources/sections/vestenoir.jpg";
import bgshirt from "../../../Assets/ressources/logo/shirt.jpg";

const SectionFlexibilite = () => {

    return(

        <div className="container-fluid SectionFlexibilite">

            {/*content*/}
            <div className="container">

                <div className="container row catprodText">

                    {/*TEXT*/}
                    <div className="col-12 col-md-6 col-lg-7 mt-5">

                        <div className="row">

                            <div className="col-12">
                                <div className="d-flex">
                                    <p className="h2 bold600">Flexibilité des commandes <br/> selon chaque besoin respectant <br/> les normes de sécurité de l'utilisateur.</p>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="80" height="80" 
                                        viewBox="0 0 24 24"
                                        className="iconTextF d-none d-xxl-block"
                                    >
                                        <path fill="#0047AB" d="M11.5 19.714v-7.427L5 8.523v7.08q0 .155.077.29t.23.23zm1 0l6.192-3.591q.154-.096.231-.23q.077-.135.077-.29v-7.08l-6.5 3.764zm-1.308.973l-6.384-3.68q-.38-.217-.593-.59Q4 16.044 4 15.61V8.391q0-.435.215-.808q.214-.373.593-.59l6.384-3.68q.38-.217.808-.217t.808.217l6.384 3.68q.38.216.594.59q.214.373.214.808v7.219q0 .434-.214.807q-.215.373-.594.59l-6.384 3.68q-.38.217-.808.217t-.808-.217m4.52-11.412l2.713-1.561l-6.117-3.541q-.154-.096-.308-.096t-.308.096L9.3 5.548zM12 11.427l2.7-1.568l-6.425-3.713l-2.7 1.567z"/>
                                    </svg>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/*Images*/}
                    <div className="col-12 col-md-6 col-lg-5 mt-5 mb-5">

                        <div className="container mforvestenoir">

                            <img src={vestenoir} alt="veste noir" className="img-fluidFlexibilite" />

                        </div>
                    
                    </div>

                </div>

            </div>
            
        </div>

    );

}

export default SectionFlexibilite;