import React from "react";
import "./SectionOffice.css";
import cochemise from "../../../../Assets/ressources/catalogue/Vtravail/coffice/chemiseelegante.jpg";
import codoudoune from "../../../../Assets/ressources/catalogue/Vtravail/coffice/doudoune.jpg";
import comaillot from "../../../../Assets/ressources/catalogue/Vtravail/coffice/maillot.jpg";
import copolo from "../../../../Assets/ressources/catalogue/Vtravail/coffice/poloperso.jpg";
import copull from "../../../../Assets/ressources/catalogue/Vtravail/coffice/pull.jpg";
import cotshirt from "../../../../Assets/ressources/catalogue/Vtravail/coffice/tshirtperso.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SectionOffice = () => {

    return(

        <div className="container-fluid SectionOffice">

            <div className="container">

                <div className="row">

                    {/*Titre collection*/}
                    <div className="col-12 mt-5">

                        <div className="d-flex">

                            <p className="h2 bold600">Collection office</p>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="55" height="55" 
                                viewBox="0 0 24 24"
                                className="iconTextCm"
                            >
                                <path fill="#0047AB" d="M3.5 20.154v-13h4v-4h9v8h4v9h-7v-4h-3v4zm1-1h3v-3h-3zm0-4h3v-3h-3zm0-4h3v-3h-3zm4 4h3v-3h-3zm0-4h3v-3h-3zm0-4h3v-3h-3zm4 8h3v-3h-3zm0-4h3v-3h-3zm0-4h3v-3h-3zm4 12h3v-3h-3zm0-4h3v-3h-3z"/>
                            </svg>

                        </div>

                    </div>

                    {/*Carte*/}
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={100}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        className="container-fluid mt-5 swiper-custom"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024:{
                                slidesPerView: 2,
                                spaceBetween: 100,
                            },
                            1440:{
                                slidesPerView: 3,
                                spaceBetween: 100,
                            }
                        }}
                    >

                        {/*Produit office 1*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cochemise} alt="chemise" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cochemise} alt="chemise" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe semi-ajustée, col mao ou autre.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manche longue ou courte, boutons au col et aux poignets.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisable : Selon vos préférences</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit office 2*/}
                        <SwiperSlide className="container swipercard">

                            <img src={copolo} alt="polo" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={copolo} alt="polo" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe semi-ajustée, col mao ou autre.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manche longue ou courte, boutons au col et aux poignets.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisable : Selon vos préférences</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit office 3*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cotshirt} alt="tshirt" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cotshirt} alt="tshirt" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe semi-ajustée, col mao ou autre.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manche longue ou courte, boutons au col et aux poignets.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisable : Selon vos préférences</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit office 4*/}
                        <SwiperSlide className="container swipercard">

                            <img src={copull} alt="pull" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={copull} alt="pull" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu drill, coton, ou synthétique pour un confort maximal.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Logo : Broderie ou sérigraphie personnalisée.</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit office 6*/}
                        <SwiperSlide className="container swipercard">

                            <img src={codoudoune} alt="doudoune" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={codoudoune} alt="doudoune" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu drill, coton, ou polyester avec rembourrage ouate pour un confort maximal.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Logo : Broderie ou sérigraphie personnalisée.</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit office 7*/}
                        <SwiperSlide className="container swipercard">

                            <img src={comaillot} alt="maillot" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={comaillot} alt="maillot" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu polyester pour un confort optimal.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Logo : Broderie ou sérigraphie personnalisée.</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                    </Swiper>

                </div>

            </div>

        </div>

    );

}

export default SectionOffice;