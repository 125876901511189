import React, { useState } from "react";
import "./SectionCodeCouleur.css";
import potPaint from "../../../Assets/animations/pot.jpg";
import fluidPaint from "../../../Assets/animations/paintfluid.jpg";
import bgPaint from "../../../Assets/ressources/logo/paint.jpg";

const SectionCodeCouleur = () => {

    /*Variable couleur de la liquide par défaut*/
    const [liquidColor, setLiquidColor] = useState("#017374");

    /*Fonction permettant de changer de couleur*/
    const changeLiquidColor = (x: string) => {

        setLiquidColor(x);

    }

    return(

        <div className="container-fluid SectionCodeCouleur">

            {/*CONTENT*/}
            <div className="container">

                <div className="container row catprodText">

                    {/*TEXTE et Bouton*/}
                    <div className="col-12 col-lg-6">

                        <div className="row">

                            {/*GRAND TITRE*/}
                            <div className="col-12 mb-5">

                                <div className="d-flex">
                                    <p className="h2 bold600">Personnalisation de vos modèles selon vos codes couleur</p>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="90" height="90"
                                        viewBox="0 0 24 24"
                                        className="iconTextCC d-none d-md-block"
                                    >
                                        <path fill="#0047AB" d="M6.385 19.616q-.592 0-1.17-.204t-1.023-.566q.497-.327.845-.878t.348-1.352q0-.847.577-1.424q.577-.576 1.423-.576t1.423.576t.577 1.424q0 1.237-.882 2.118t-2.118.881m0-1q.825 0 1.412-.587t.588-1.412q0-.425-.288-.713t-.712-.288t-.713.288t-.287.713q0 .575-.138 1.05t-.362.9q.125.05.25.05zM11.25 14.5l-1.711-1.711l8.18-8.181q.275-.275.688-.288t.712.288l.312.311q.3.3.3.7t-.3.7zm-3.866 2.116"/>
                                    </svg>
                                </div>
                                
                            </div>

                            {/*Code couleur*/}
                            <div className="col-12 mb-2">

                                <div className="d-flex justify-content-evenly">

                                    <button onClick={() => changeLiquidColor('#017374')} className="btnparam btnbgcolor1"></button>
                                    <button onClick={() => changeLiquidColor('#CC5500')} className="btnparam btnbgcolor2"></button>
                                    <button onClick={() => changeLiquidColor('#FF00FF')} className="btnparam btnbgcolor3"></button>
                                    <button onClick={() => changeLiquidColor('#50C878')} className="btnparam btnbgcolor4"></button>
                                    <button onClick={() => changeLiquidColor('#0047AB')} className="btnparam btnbgcolor5"></button>
                                    <button onClick={() => changeLiquidColor('#FFDB58')} className="btnparam btnbgcolor6"></button>
                                    <button onClick={() => changeLiquidColor('#9966CC')} className="btnparam btnbgcolor7"></button>
                                    <button onClick={() => changeLiquidColor('#40E0D0')} className="btnparam btnbgcolor8"></button>
                                    <button onClick={() => changeLiquidColor('#FF7F50')} className="btnparam btnbgcolor9"></button>
                                    <button onClick={() => changeLiquidColor('#83f52c')} className="btnparam btnbgcolor10"></button>
                                    
                                </div>
                                
                            </div>

                        </div>

                    </div>

                    {/*Colors*/}
                    <div className="col-12 col-lg-6">

                        <div className="container">
                    
                            <div className='water-wave' style={{background: liquidColor}}>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            {/*ICONE SAFE BACKGROUND en XXL*/}
            <div className="container d-none d-xxl-block">
                <div className="bgpaint2">
                    <img src={bgPaint} alt="paint" className="img-fluid"/>
                </div>
            </div>

        </div>

    );

}

export default SectionCodeCouleur;