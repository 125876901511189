import React from "react";
import "./SectionQHSSE.css";
import imgQhsse from "../../../Assets/ressources/photos/P5.jpg";
import bgnoeud from "../../../Assets/ressources/logo/norme.jpg";

const SectionQHSSE = () => {

    return(

        <div className="container-fluid SectionQHSSE">
            
            {/*CONTENT*/}
            <div className="container">

                <div className="row container catprodText">

                    {/*TEXT*/}
                    <div className="col-12 col-md-6 col-lg-7 mt-5">

                        <div className="row">

                            {/*Titre*/}
                            <div className="col-12">

                                <div className="d-flex">

                                    <p className="h1 bold600">Respect de la norme <span className="textColor">QHSSE</span></p>

                                </div>

                            </div>
                            
                            {/*paragraphe*/}
                            <div className="col-12">
                                <p className="fs14 bold500">Nous adhérons rigoureusement à la norme <span className=" bold600 textColor"> QHSSE </span>(Qualité, Hygiène, Santé, Sécurité, Environnement), garantissant ainsi des produits conformes aux standards les plus élevés en matière de qualité et de sécurité.</p>
                            </div>

                        </div>

                    </div>

                     {/*Images*/}
                     <div className="col-12 col-md-6 col-lg-5 mt-5">

                        <div className="container mforvestenoir">

                            <img src={imgQhsse} alt="monsieur" className="img-fluidFlexibilite" />

                        </div>

                    </div>

                </div>

            </div>

             {/*ICONE SAFE BACKGROUND en XXL*/}
             <div className="container d-none d-xxl-block">

                <div className="bgsav1">
                    <img src={bgnoeud} alt="noeud" className="img-fluid"/>
                </div>
                <div className="bgsav2">
                    <img src={bgnoeud} alt="noeud" className="img-fluid"/>
                </div>

            </div>


        </div>

    );

}

export default SectionQHSSE;