import React from "react";
import "./SectionHotelC.css";
import achchaussure from "../../../../Assets/ressources/catalogue/Accessoires/chotel/chaussurecuisine.jpg";
import achcouette from "../../../../Assets/ressources/catalogue/Accessoires/chotel/couette.jpg";
import achlinge from "../../../../Assets/ressources/catalogue/Accessoires/chotel/lingecuisine.jpg";
import achoreiller from "../../../../Assets/ressources/catalogue/Accessoires/chotel/oreiller.jpg";
import achserviette from "../../../../Assets/ressources/catalogue/Accessoires/chotel/serviettetable.jpg";
import achservietteT from "../../../../Assets/ressources/catalogue/Accessoires/chotel/serviettetoilette.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SectionHotelC = () => {

    return(

        <div className="container-fluid SectionHotelC">

            <div className="container">

                <div className="row">

                    {/*Titre collection*/}
                    <div className="col-12 mt-5">

                        <div className="d-flex">

                            <p className="h2 bold600">Collection hotel / restaurant</p>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="55" height="55"
                                viewBox="0 0 24 24"
                                className="iconTextCm"
                            >
                                <path fill="#0047AB" d="M11 21v-1h11v1zm1-2.384q0-1.737 1.15-3.01q1.15-1.271 2.85-1.448v-.414q0-.194.143-.347t.357-.153q.194 0 .347.153t.153.347v.414q1.694.177 2.847 1.449T21 18.616zm1.202-1.006h6.565q-.301-1.098-1.193-1.809q-.891-.71-2.074-.71q-1.188 0-2.092.71t-1.206 1.809M2.039 11.923V3.769h4v1.296l7.615-2.219l7 2.192V5.5q0 .865-.692 1.49t-1.654.625h-2.539V8.1q0 .51-.353.936q-.353.425-.832.612l-6.172 2.275zm1-1h2V4.769h-2zm3 0h2.215l5.992-2.202q.237-.08.38-.28q.143-.199.143-.46v-.365H12.84l-2.925.969l-.33-.958l3.105-1.011h5.618q.34 0 .774-.211q.433-.21.51-.647l-5.988-1.85l-7.565 2.208z"/>
                            </svg>

                        </div>

                    </div>

                    {/*Carte*/}
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={100}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        className="container-fluid mt-5 swiper-custom"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024:{
                                slidesPerView: 2,
                                spaceBetween: 100,
                            },
                            1440:{
                                slidesPerView: 3,
                                spaceBetween: 100,
                            }
                        }}
                    >

                        {/*Produit Hotel 1*/}
                        <SwiperSlide className="container swipercard">

                            <img src={achserviette} alt="serviette" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={achserviette} alt="serviette" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">100% coton de qualité supérieure.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sérigraphie et broderie disponibles</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Différentes tailles et couleurs, uni ou imprimé</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Finition : Ourlet simple</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit Hotel 2*/}
                        <SwiperSlide className="container swipercard">

                            <img src={achservietteT} alt="serviette" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={achservietteT} alt="serviette" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">100% coton de qualité supérieure.</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sérigraphie et broderie disponibles</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Différentes tailles et couleurs, uni ou imprimé</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Finition : Ourlet simple</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit Hotel 3*/}
                        <SwiperSlide className="container swipercard">

                            <img src={achchaussure} alt="chaussure" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={achchaussure} alt="chaussure" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Hydrofuge, souple</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Anatomique, antistatique et perforée</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Embout de sécurité en acier</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Qualité : Supérieure, respirante et résistante</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit Hotel 4*/}
                        <SwiperSlide className="container swipercard">

                            <img src={achoreiller} alt="oreiller" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={achoreiller} alt="oreiller" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">100% coton de qualité supérieure</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie et broderie disponibles</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Différentes tailles et couleurs, uni ou imprimé</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Oreiller 65x65 cm, finition de qualité</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit Hotel 5*/}
                        <SwiperSlide className="container swipercard">

                            <img src={achcouette} alt="couette" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={achcouette} alt="couette" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">100% coton de qualité supérieure</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie et broderie disponibles</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Différentes tailles et couleurs, uni ou imprimé</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Housse 220x240 cm, finition de qualité</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Produit Hotel 6*/}
                        <SwiperSlide className="container swipercard">

                            <img src={achlinge} alt="linge" className="img-fluidCard"/>

                            {/*HOVER carte*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={achlinge} alt="linge" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">100% coton de qualité supérieur</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie et broderie disponibles</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Différentes tailles et couleurs, uni ou imprimé</p>

                                            </div>

                                        </div>

                                        <div className="col-12">

                                            <div className="d-flex">

                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>

                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Linges de cuisine faits main</p>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                    </Swiper>

                </div>

            </div>

        </div>

    );

}

export default SectionHotelC;