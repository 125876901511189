import React, { useEffect, useState } from "react";
import "./Menu.css";
import logo from "../../Assets/ressources/logo/logoA.jpg";
import { NavLink, useLocation } from "react-router-dom";
import SearchBar from "./SmallDeviceSearch/SearchBar/SearchBar";

const Menu = () => {

    /*Gestion Scroll automatique => changement de classe*/
    const [scrollPosition, setScrollPosition] = useState(0);

    /*Changement de couleur pour la page contact seulement*/
    const location = useLocation();

    const isContact = location.pathname === '/contact';
    const isSearchMobile = location.pathname === '/smallDeviceSearch';
    const isCoIndustrielle = location.pathname === '/collectionindustrielle';
    const isCoMedicale = location.pathname === '/collectionmedicale';
    const isCoHotel = location.pathname === '/collectionhotel';
    const isCoOffice = location.pathname === '/collectionoffice'
    const isCoForce = location.pathname === '/collectionforce';
    const isCoEsthetique = location.pathname === '/collectionesthetique';
    const isCoPluie = location.pathname === '/collectionpluie';
    const isPolitique = location.pathname === '/politiqueconfidentialite';
    const isConditions = location.pathname === '/conditiondevente';
    const isFAQ = location.pathname === '/faq';
    const isMentionLegale = location.pathname === '/mentionlegale';

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    //Gestion Scroll
    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    return(

        <div className={

                isSearchMobile ? "persoMenu disapear":
                    scrollPosition > 0 ? 'persoMenu scrolled': 
                'persoMenu'

            } 
            
            style={{

                background: isContact || isCoIndustrielle || isCoMedicale || isCoHotel || isCoOffice || isCoForce || isCoEsthetique || isCoPluie || isPolitique || isConditions || isFAQ || isMentionLegale ? "rgba(54, 54, 54)" : ""
            
            }}
            >

            <div className="container d-flex justify-content-between">

                {/*BARRE DE NAVIGATION*/}
                <nav className="navbar navbar-expand-lg navbar-dark">
                        
                    {/*BOUTON MENU BURGER: affichage sur mobile uniquement*/}
                    <button className="navbar-toggler btn" data-bs-toggle="collapse" data-bs-target="#menu">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg"
                            width="40" height="40"
                            viewBox="0 0 16 16"
                        >
                            <path fill="white" fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                        </svg>
                    </button>
    
                    {/*MENU DE NAVIGATION*/}
                    <div id="menu" className="collapse navbar-collapse">
    
                        <ul className="navbar-nav">
    
                            {/*PRODUITS*/}
                            <li className="mx-2">
                                <NavLink to="/produits" className="nav-link text-white">Produits</NavLink>
                            </li>
    
                            {/*SOUS MENU SERVICES */}
                            <li className="nav-item dropdown mx-2">
                                <NavLink to="#" className="nav-link text-white" data-bs-toggle="dropdown">Services</NavLink>
    
                                <ul className="dropdown-menu rounded-4">
    
                                    {/*PERSONNALISATION */}
                                    <li className="my-3 persoPerso">
                                        <NavLink to="/personnalisation" className="dropdown-item bg-white text-dark d-flex justify-content-around">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="#FF00FF" d="M6.385 19.616q-.592 0-1.17-.204t-1.023-.566q.497-.327.845-.878t.348-1.352q0-.847.577-1.424q.577-.576 1.423-.576t1.423.576t.577 1.424q0 1.237-.882 2.118t-2.118.881m0-1q.825 0 1.412-.587t.588-1.412q0-.425-.288-.713t-.712-.288t-.713.288t-.287.713q0 .575-.138 1.05t-.362.9q.125.05.25.05zM11.25 14.5l-1.711-1.711l8.18-8.181q.275-.275.688-.288t.712.288l.312.311q.3.3.3.7t-.3.7zm-3.866 2.116"/></svg>
                                            <span className="mt-1 fw-bold persoPersoText">Personnalisation</span>
                                        </NavLink>
                                    </li>
    
                                    {/*LIVRAISON ET RETOUR*/}
                                    <li className="my-3 persoPerso">
                                        <NavLink to="/livraison" className="dropdown-item bg-white text-dark d-flex justify-content-around">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" className="mx-2"><path fill="#FF00FF " d="M5.688 19.116q-1.092 0-1.851-.763q-.76-.763-.76-1.853H1.692V6.616q0-.691.463-1.153T3.308 5h13.23v3.616h2.308l3.462 4.653V16.5h-1.616q0 1.09-.764 1.853t-1.856.762t-1.85-.762t-.76-1.853H8.307q0 1.096-.764 1.856t-1.856.76m.004-1q.675 0 1.145-.47t.47-1.146t-.47-1.145t-1.145-.47t-1.145.47t-.47 1.145t.47 1.145t1.145.47m-3-2.615h.647q.213-.662.869-1.138t1.484-.478q.79 0 1.466.468q.675.467.888 1.148h7.493V6H3.307q-.231 0-.423.192q-.193.192-.193.423zm15.385 2.616q.675 0 1.145-.47q.47-.471.47-1.146t-.47-1.145t-1.145-.47t-1.145.47t-.47 1.145t.47 1.145t1.145.47M16.538 13.5h4.712l-2.942-3.884h-1.77zm-7.422-2.75"/></svg>
                                        <span className="mt-1 fw-bold mx-2">Livraison et retour</span>
                                        </NavLink>
                                    </li>
    
                                </ul>
    
                            </li>
    
                            {/*CONTACT*/}
                            <li className="mx-2">
                                <NavLink to="/contact" className="nav-link text-white">Contact</NavLink>
                            </li>
    
                            {/*NOUS*/}
                            <li className="mx-2">
                                <NavLink to="/nous" className="nav-link text-white">Nous</NavLink>
                            </li>
    
                        </ul>
    
                    </div>
                        
                </nav>
    
                {/*LOGO HOME*/}
                <div className="container persoLogoTita">
                    <NavLink to="/">
                        <img src={logo} alt="Logo titanium22" className="img-fluid"/>
                    </NavLink>
                </div>
    
                {/*SEARCHBAR*/}
                <div className="d-none d-lg-block">
                    <SearchBar></SearchBar>
                </div>

                {/*Pour tablette et mobile*/}
                <div className="d-block d-lg-none">
            
                    <NavLink to="/smallDeviceSearch">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 24 24"
                            className="searchBar"
                        >
                            <path fill="#fff" d="m19.485 20.154l-6.262-6.262q-.75.639-1.725.989t-1.96.35q-2.402 0-4.066-1.663T3.808 9.503T5.47 5.436t4.064-1.667t4.068 1.664T15.268 9.5q0 1.042-.369 2.017t-.97 1.668l6.262 6.261zM9.539 14.23q1.99 0 3.36-1.37t1.37-3.361t-1.37-3.36t-3.36-1.37t-3.361 1.37t-1.37 3.36t1.37 3.36t3.36 1.37"/>
                        </svg>
                    </NavLink>   

                </div>
                

                
            </div>
           
        </div>

    );

}

export default Menu;