import React from "react";
import "./SectionMedicale.css";
import cmdocteur from "../../../../Assets/ressources/catalogue/Vtravail/cmedical/TenueDocteur.jpg";
import cminfirmier from "../../../../Assets/ressources/catalogue/Vtravail/cmedical/TenueInfirmier.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SectionMedicale = () => {

    return(

        <div className="container-fluid SectionMedicale">
            
            <div className="container">

                <div className="row">

                    {/*Titre collection*/}
                    <div className="col-12 mt-5">

                        <div className="d-flex">

                            <p className="h2 bold600">Collection médicale</p>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="55" height="55"
                                viewBox="0 0 24 24"
                                className="iconTextCm"
                            >
                                <path fill="#0047AB" d="M11.25 16.5h1.5v-3.75h3.75v-1.5h-3.75V7.5h-1.5v3.75H7.5v1.5h3.75zM5.616 20q-.691 0-1.153-.462T4 18.384V5.616q0-.691.463-1.153T5.616 4h12.769q.69 0 1.153.463T20 5.616v12.769q0 .69-.462 1.153T18.384 20zm0-1h12.769q.23 0 .423-.192t.192-.424V5.616q0-.231-.192-.424T18.384 5H5.616q-.231 0-.424.192T5 5.616v12.769q0 .23.192.423t.423.192M5 5v14z"/>
                            </svg>

                        </div>

                    </div>

                    {/*Carte*/}
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={100}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        className="container-fluid mt-5 swiper-custom"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024:{
                                slidesPerView: 2,
                                spaceBetween: 100,
                            },
                            1440:{
                                slidesPerView: 3,
                                spaceBetween: 100,
                            }
                        }}
                    >
                        {/*Produit medical 1*/}
                        <SwiperSlide className="container swipercard">

                            <img src={cmdocteur} alt="blouse" className="img-fluidCard"/>

                            {/*HOVER CARTE*/}
                            <div className="swipercardVar4">

                                <div className="d-flex">

                                    <img src={cmdocteur} alt="blouse" className="d-none d-lg-block img-fluidCard2"/>

                                    <div className="container row mt-3">

                                        <div className="col-12">
                                            <div className="d-flex">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>
                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL</p>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="d-flex">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>
                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Drill ou coton pour un confort maximal</p>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="d-flex">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>
                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manches courtes ou longues, avec ou sans poches</p>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="d-flex">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>
                                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Logo : Broderie ou sérigraphie</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>
                        
                        {/*Produit medical 2*/}
                        <SwiperSlide className="container swipercard">
                            <img src={cminfirmier} alt="blouse" className="img-fluidCardBlouse"/>

                            {/*HOVER CARTE*/}
                            <div className="swipercardVar4">
                                <div className="d-flex">
                                    <img src={cminfirmier} alt="blouse" className="d-none d-lg-block img-fluidCardBlouse2"/>
                                    <div className="container row mt-3">
                                        <div className="col-12">
                                            <div className="d-flex d-flex-gap">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>
                                                <p className="fs12 bold600 cardtext1024">Tailles disponibles : S, M, L, XL, XXL, 3XL, 4XL</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex d-flex-gap">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>
                                                <p className="fs12 bold600 cardtext1024">Matériaux : Tissu drill ou coton pour un confort maximal.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex d-flex-gap">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>
                                                <p className="fs12 bold600 cardtext1024">Personnalisation : Manche courte ou longue, avec ou sans poches</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex d-flex-gap">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="greenLogo"
                                                >
                                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                </svg>
                                                <p className="fs12 bold600 cardtext1024">Options de marquage : Broderie ou sérigraphie pour ajouter un logo</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>

                    </Swiper>
                    

                </div>

            </div>

        </div>

    );

}

export default SectionMedicale;