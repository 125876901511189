import React from "react";
import "./SectionQualite.css";
import imgQualite from "../../../Assets/ressources/photos/P1.jpg";
import bgnoeud from "../../../Assets/ressources/logo/norme.jpg";

const SectionQualite = () => {

    return(

        <div className="container-fluid SectionQualite">

            {/*Content*/}
            <div className="container">

                <div className="row container catprodText">

                    {/*TEXT*/}
                    <div className="col-12 col-md-6 col-lg-7 mt-5">

                        <div className="row">

                            {/*Titre*/}
                            <div className="col-12">

                                <div className="d-flex">

                                    <p className="h1 bold600">Qualité</p>

                                </div>

                            </div>
                            
                            {/*paragraphe*/}
                            <div className="col-12">
                                <p className="fs14 bold500">Chez Titanium22, la qualité est notre priorité. <br/> Notre équipe de <span className="textColor bold600">contrôle de qualité (QAQC)</span> inspecte chaque étape de la production <br/> pour garantir des produits durables, confortables et sécurisés.</p>
                            </div>

                        </div>

                    </div>

                     {/*Images*/}
                     <div className="col-12 col-md-6 col-lg-5 mt-5 mb-5">

                        <div className="container mforvestenoir">

                            <img src={imgQualite} alt="monsieur" className="img-fluidFlexibilite" />

                        </div>

                    </div>

                </div>

            </div>

            {/*ICONE SAFE BACKGROUND en XXL*/}
            <div className="container d-none d-xxl-block">

                <div className="bgsav1">
                    <img src={bgnoeud} alt="noeud" className="img-fluid"/>
                </div>
                <div className="bgsav2">
                    <img src={bgnoeud} alt="noeud" className="img-fluid"/>
                </div>

            </div>

        </div>

    );

}

export default SectionQualite;