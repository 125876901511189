import React from "react";
import "./SectionTarif.css";
import imgcapital from "../../../Assets/ressources/logo/greenhere.jpg";
import imgperipherie from "../../../Assets/ressources/logo/greenbicycle.jpg";
import imgprovince from "../../../Assets/ressources/logo/truck.jpg";
import imgetranger from "../../../Assets/ressources/logo/plane.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SectionTarif = () => {

    return(
        
        <div className="container-fluid SectionTarif">

            <div className="container">

                <div className="row">

                    {/*TITRE TARIF*/}
                    <div className="col-12 mt-5">
                        
                        <div className="d-flex justify-content-center">

                            <p className="h1 bold600">Tarifs de livraison</p>

                        </div>

                    </div>

                    {/*Carte*/}
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={25}
                        slidesPerView={4}
                        navigation
                        pagination={{ clickable: true }}
                        className="container-fluid mt-5 swiper-custom2"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024:{
                                slidesPerView: 2,
                                spaceBetween: 100,
                            },
                            1440:{
                                slidesPerView: 4,
                                spaceBetween: 25,
                            }
                        }} 
                    >
                        
                        {/*Tarif zone 1*/}
                       <SwiperSlide className="container cardTarif">

                            <div className="container row">

                                {/*Texte*/}
                                <div className="col-12 text-center">
                                    <p className="h4 bold600 mt-4 mlforzone">Zone 1</p>
                                </div>

                                {/*Logo*/}
                                <div className="col-12 w-100">
                                    <img src={imgcapital} alt="localisation" className="img-fluidLogoTarif"/>
                                </div>

                                {/*Tarif*/}
                                <div className="col-12 text-center mt-4 mlfortarif">
                                    <div className="container d-flex flex-column">
                                        <p className="fs14 bold600">A partir de</p>
                                        <p className="h4 bold600 tarifcolor">44 500 Ariary</p>
                                    </div>
                                </div>                                

                            </div>

                            <div className="container cardTarifHover">

                                <div className="container row">

                                    <div className="col-12 text-center">

                                        <p className="h4 bold600 mt-4 tarifcolor">Quartier</p>

                                        <span className="h6 bold400">Analamahitsy - Androhibe - Ivandry - Ankorondrano - Anosivavaka - Andraharo - Ankazomanga </span>

                                    </div>

                                    <div className="col-12 text-center mt-4">

                                        <p className="h4 bold600 tarifcolor">Tarif</p>
                                        <p className="bold400"><span className="tarifcolor bold600">44 500 Ariary</span> pour article moins de 300 pièces.</p>

                                    </div>

                                    <div className="col-12 text-center mt-4">

                                        <p className="h6 bold500">Sur devis si plus de 300 pièces.</p>

                                    </div>

                                </div>

                            </div>

                       </SwiperSlide>

                       {/*Tarif zone 2*/}
                       <SwiperSlide className="container cardTarif">

                            <div className="container row">

                                {/*Texte*/}
                                <div className="col-12 text-center">
                                    <p className="h4 bold600 mt-4 mlforzone">Zone 2</p>
                                </div>

                                {/*Logo*/}
                                <div className="col-12 w-100">
                                    <img src={imgperipherie} alt="bicyclette" className="img-fluidLogoTarif"/>
                                </div>

                                {/*Tarif*/}
                                <div className="col-12 text-center mt-4 mlfortarif">
                                    <div className="container d-flex flex-column">
                                        <p className="fs14 bold600">A partir de</p>
                                        <p className="h4 bold600 tarifcolor">56 500 Ariary</p>
                                    </div>
                                </div>                                

                            </div>

                            <div className="container cardTarifHover">

                                <div className="container row">

                                    <div className="col-12 text-center">

                                        <p className="h4 bold600 mt-4 tarifcolor">Quartier</p>

                                        <span className="h6 bold400">Analakely - Ambanidia - Mahamasina - Ankadimbahoaka - Tanjombato - Iavoloha - By Pass</span>

                                    </div>

                                    <div className="col-12 text-center mt-4">

                                        <p className="h4 bold600 tarifcolor">Tarif</p>
                                        <p className="bold400"><span className="tarifcolor bold600">56 500 Ariary</span> pour article moins de 300 pièces.</p>

                                    </div>

                                    <div className="col-12 text-center mt-4">

                                        <p className="h6 bold500">Sur devis si plus de 300 pièces.</p>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*Tarif zone 3*/}
                       <SwiperSlide className="container cardTarif">

                            <div className="container row">

                                {/*Texte*/}
                                <div className="container col-12 text-center">
                                    <p className="h4 bold600 mt-4 mlforzone">Zone 3</p>
                                </div>

                                {/*Logo*/}
                                <div className="col-12 w-100">
                                    <img src={imgprovince} alt="camion" className="img-fluidLogoTarif"/>
                                </div>

                                {/*Tarif*/}
                                <div className="col-12 text-center mt-4 mlfortarif">
                                    <div className="container d-flex flex-column">
                                        <p className="fs14 bold600">A partir de</p>
                                        <p className="h4 bold600 tarifcolor">62 500 Ariary</p>
                                    </div>
                                </div>                                

                            </div>

                            <div className="container cardTarifHover">

                                <div className="container row">

                                    <div className="col-12 text-center">

                                        <p className="h4 bold600 mt-4 tarifcolor">Quartier</p>

                                        <span className="h6 bold400">Itaosy - Ambohibao - Talatamaty - Ivato</span>

                                    </div>

                                    <div className="col-12 text-center mt-4">

                                        <p className="h4 bold600 tarifcolor">Tarif</p>
                                        <p className="bold400"><span className="tarifcolor bold600">62 500 Ariary</span> pour article moins de 300 pièces.</p>

                                    </div>

                                    <div className="col-12 text-center mt-4">

                                        <p className="h6 bold500">Sur devis si plus de 300 pièces.</p>

                                    </div>

                                </div>

                            </div>

                        </SwiperSlide>

                        {/*zone personnalisée*/}
                       <SwiperSlide className="container cardTarif">

                            <div className="container row">

                                {/*Texte*/}
                                <div className="container col-12 text-center">
                                    <p className="h4 bold600 mt-4 mlforzone">Zone personnalisée</p>
                                </div>

                                {/*Logo*/}
                                <div className="col-12 w-100">
                                    <img src={imgetranger} alt="avion" className="img-fluidLogoTarif"/>
                                </div>

                                {/*Tarif*/}
                                <div className="col-12 text-center mlfortarif">
                                    <div className="container d-flex flex-column">
                                        <p className="h4 bold600 tarifcolor mt-4">Prix sur devis</p>
                                    </div>
                                </div>                                

                            </div>

                        </SwiperSlide>

                    </Swiper>

                </div>

            </div>

        </div>

    );

}

export default SectionTarif;