import React from "react";
import "./HeaderNous.css";
import groupuser from "../../../Assets/ressources/logo/user.jpg";
import cveste from "../../../Assets/ressources/logo/veste.jpg";
import cgilet from "../../../Assets/ressources/logo/gilet.jpg";
import caccessoire from "../../../Assets/ressources/logo/accessoire.jpg";
import imgheadernous from "../../../Assets/ressources/photos/P8.jpg";
import mdg from "../../../Assets/ressources/logo/mdg.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const HeaderNous = () => {

    return(

        <div className="container-fluid HeaderNous">

            <div className="container">

                <div className="row entete">

                    {/*Présentation*/}
                    <div className="col-12 col-md-7 mb-5">

                        <div className="container">

                            <div className="d-flex gapImgMdg">    

                                <h1 className="h1 text-white bold600 mb-3">Qui est Titanium 22 ?</h1>

                                <div className="containImgMdgNous">
                                    <img src={mdg} alt="Madagascar Flag" className="imgMdg"/>
                                </div>

                            </div>

                            <p className="text-white fs14 bold400 mb-5">Titanium22 est une entreprise du <span className="textColor bold600">Groupe User</span>, <br/> spécialisée dans la confection de vêtements de sécurité, de travail, ainsi que des accessoires associés.</p>

                            {/*CAROUSEL LOGO*/}
                            <div className="container marginForCarouselLogoNous">

                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                    slidesPerView={1}
                                    autoplay={{delay: 2000}}
                                    loop={true}
                                    speed={1000}
                                    className="containMobile"
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                
                                        },
                                        768: {
                                            slidesPerView: 1,
                                        
                                        },
                                        1024:{
                                            slidesPerView: 1,
                                           
                                        },
                                        1440:{
                                            slidesPerView: 1,
                                          
                                        }
                                    }} 
                                >   
                                

                                    <SwiperSlide>

                                        <img src={groupuser} className="d-block c-i-h-nous" alt="gilet" />

                                    </SwiperSlide>

                                    <SwiperSlide>

                                        <img src={cgilet} className="d-block c-i-h-nous" alt="gilet" />

                                    </SwiperSlide>

                                    <SwiperSlide>

                                        <img src={groupuser} className="d-block c-i-h-nous" alt="gilet" />

                                    </SwiperSlide>

                                    <SwiperSlide>

                                        <img src={cveste} className="d-block c-i-h-nous" alt="veste" />

                                    </SwiperSlide>

                                    <SwiperSlide>

                                        <img src={groupuser} className="d-block c-i-h-nous" alt="gilet" />

                                    </SwiperSlide>

                                    <SwiperSlide>

                                        <img src={caccessoire} className="d-block c-i-h-nous" alt="casque" />

                                    </SwiperSlide>

                                </Swiper>

                            </div>

                        </div>

                    </div>

                    {/*IMAGE à droite*/}
                    <div className="col-12 col-md-4 d-none d-md-block">

                        <div className="container">

                            <div className="headerimagenous">

                                <img src={imgheadernous} alt="homme" className="img-fluidheaderimagenous" />

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    );

}

export default HeaderNous;