import React from "react";
import "./SectionCategorie.css";
import vsimg from "../../../Assets/ressources/produits/combinaison.jpg";
import vtimg from "../../../Assets/ressources/produits/travail.jpg";
import aimg from "../../../Assets/ressources/produits/casque.jpg";
import pimg from "../../../Assets/ressources/produits/porter.jpg";
import bgsac from "../../../Assets/ressources/logo/bag.jpg";
import { NavLink } from "react-router-dom";

const SectionCategorie = () => {

    return(

        <div className="container-fluid SectionCategorie pb-5">
            
            {/*CATEGORIES DES PRODUITS*/}
            <div className="catprod">

                <p className="h1 text-center bold600 catprodText">Nos catégories de produits</p>

                {/*Liste des GRIDS des catégories de produits*/}
                <div className="container text-center mt-3">

                    <div className="row mlforlg">
                        
                        {/*Vêtement de travail*/}
                        <NavLink to="/cataloguetravail" className="col-12 col-md-6 col-lg-3 bgGrid2 mx-4 my-5">

                            <div className="row">

                                {/*Affichage Titre du catégories*/}
                                <div className="col-12 mt-3">
                                    <p className="h4 text-white text-start mx-3 bold600">Vêtements de <br/> travail</p>
                                </div>

                                {/*Image lui représentant*/}
                                <div className="container col-11 mt-4 rounded-4 bgSmallGrid2">
                                    <img src={vtimg} alt="travail" className="imgInGrid"/>
                                </div>  
                            </div>

                        </NavLink>
                        
                        {/*Vêtement de sécurité*/}
                        <NavLink to="/cataloguesecurite" className="col-12 col-md-6 col-lg-3 bgGrid mx-4 my-5">

                            <div className="row">

                                {/*Affichage Titre du catégories*/}
                                <div className="col-12 mt-3">
                                    <p className="h4 text-white text-start mx-3 bold600">Vêtements de <br/> sécurité</p>
                                </div>

                                {/*Image lui représentant*/}
                                <div className="container col-11 bgSmallGrid mt-4 rounded-4">
                                    <img src={vsimg} alt="combinaison" className="imgInGrid"/>
                                </div>  
                            </div>

                        </NavLink>

                        {/*Accessoires*/}
                        <NavLink to="/catalogueaccessoire" className="col-12 col-md-6 col-lg-3 bgGrid3 mx-4 my-5">
                            
                            <div className="row">

                                {/*Affichage Titre du catégories*/}
                                <div className="col-12 mt-3">
                                    <p className="h4 text-white text-start mx-3 bold600">Accessoires</p>
                                </div>

                                {/*Image lui représentant*/}
                                <div className="container col-11 rounded-4 bgSmallGrid3">
                                    <img src={aimg} alt="casque" className="imgInGrid"/>
                                </div>  

                            </div>

                        </NavLink>

                        {/*Prêt à porter*/}
                        <NavLink to="/pretaporter" className="col-12 col-md-6 col-lg-3 bgGrid4 mx-4 my-5">
                            
                            <div className="row">

                                {/*Affichage Titre du catégories*/}
                                <div className="col-12 mt-3">
                                    <p className="h4 text-white text-start mx-3 bold600">Prêt-à-porter</p>
                                </div>

                                {/*Image lui représentant*/}
                                <div className="container col-11 rounded-4 bgSmallGrid4">
                                    <img src={pimg} alt="casque" className="imgInGrid"/>
                                </div>  

                            </div>

                        </NavLink>

                    </div>

                </div>

            </div>
            
            {/*ICONE BAG BACKGROUND en XXL*/}
            <div className="container d-none d-xxl-block">
                <div className="bgsac1">
                    <img src={bgsac} alt="sacs" className="img-fluid"/>
                </div>
                <div className="bgsac2">
                    <img src={bgsac} alt="sacs" className="img-fluid"/>
                </div>
                <div className="bgsac3">
                    <img src={bgsac} alt="sacs" className="img-fluid"/>
                </div>
            </div>


        </div>

    );

}

export default SectionCategorie;