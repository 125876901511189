import React from 'react';
import Menu from './Components/Menu/Menu';
import Accueil from './Components/Accueil/Accueil';
import Produits from './Components/Produits/Produits';
import CatalogueSecurite from './Components/Catalogue/CatalogueSecurite/CatalogueSecurite';
import CatalogueTravail from './Components/Catalogue/CatalogueTravail/CatalogueTravail';
import CatalogueAccessoire from './Components/Catalogue/CatalogueAccessoire/CatalogueAccessoire';
import CataloguePretPorter from './Components/Catalogue/CataloguePretPorter/CataloguePretPorter';
import Personnalisation from './Components/Personnalisation/Personnalisation';
import Livraison from './Components/Livraison/Livraison';
import Contact from './Components/Contact/Contact';
import Nous from './Components/Nous/Nous';
import Footer from './Components/Footer/Footer';
import SmallDeviceSearch from './Components/Menu/SmallDeviceSearch/SmallDeviceSearch';
import CollectionIndustrielle from './Components/Collections/CollectionIndustrielle/CollectionIndustrielle';
import CollectionMedicale from './Components/Collections/CollectionMedicale/CollectionMedicale';
import CollectionHotel from './Components/Collections/CollectionHotel/CollectionHotel';
import CollectionOffice from './Components/Collections/CollectionOffice/CollectionOffice';
import CollectionForce from './Components/Collections/CollectionForce/CollectionForce';
import CollectionEsthetique from './Components/Collections/CollectionEsthetique/CollectionEsthetique';
import CollectionPluie from './Components/Collections/CollectionPluie/CollectionPluie';
import PolitiqueConfi from './Components/PolitiqueConfi/PolitiqueConfi';
import ConditionVente from './Components/ConditionVente/ConditionVente';
import Faq from './Components/Faq/Faq';
import MentionLegale from './Components/MentionLegale/MentionLegale';
import { Routes, Route } from 'react-router-dom';
import './App.css';

const App = () => {

  return(

    <div className='persoFont'>
      
      {/*MENU DE NAVIGATION*/}
      <Menu></Menu>

      {/*PAGES*/}
      <Routes>
        <Route path='/' element={<Accueil></Accueil>}></Route>
        <Route path='/produits' element={<Produits></Produits>}></Route>
        <Route path='/cataloguesecurite' element={<CatalogueSecurite></CatalogueSecurite>}></Route>
        <Route path='/cataloguetravail' element={<CatalogueTravail></CatalogueTravail>}></Route>
        <Route path='/catalogueaccessoire' element={<CatalogueAccessoire></CatalogueAccessoire>}></Route>
        <Route path='/pretaporter' element={<CataloguePretPorter></CataloguePretPorter>}></Route>
        <Route path='/personnalisation' element={<Personnalisation></Personnalisation>}></Route>
        <Route path='/livraison' element={<Livraison></Livraison>}></Route>
        <Route path='/contact' element={<Contact></Contact>}></Route>
        <Route path='/nous' element={<Nous></Nous>}></Route>
        <Route path='/smallDeviceSearch' element={<SmallDeviceSearch></SmallDeviceSearch>}></Route>
        <Route path='/collectionindustrielle' element={<CollectionIndustrielle></CollectionIndustrielle>}></Route>
        <Route path='/collectionmedicale' element={<CollectionMedicale></CollectionMedicale>}></Route>
        <Route path='/collectionhotel' element={<CollectionHotel></CollectionHotel>}></Route>
        <Route path='/collectionoffice' element={<CollectionOffice></CollectionOffice>}></Route>
        <Route path='/collectionforce' element={<CollectionForce></CollectionForce>}></Route>
        <Route path='/collectionesthetique' element={<CollectionEsthetique></CollectionEsthetique>}></Route>
        <Route path='/collectionpluie' element={<CollectionPluie></CollectionPluie>}></Route>
        <Route path='/politiqueconfidentialite' element={<PolitiqueConfi></PolitiqueConfi>}></Route>
        <Route path='/conditiondevente' element={<ConditionVente></ConditionVente>}></Route>
        <Route path='/faq' element={<Faq></Faq>}></Route>
        <Route path='/mentionlegale' element={<MentionLegale></MentionLegale>}></Route>
      </Routes>

      {/*FOOTER*/}
      <Footer></Footer>

    </div>

  );

}

export default App;
