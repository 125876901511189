import React, { useEffect } from "react";
import HeaderNous from "./HeaderNous/HeaderNous";
import SectionEquipe from "./SectionEquipe/SectionEquipe";
import SectionQHSSE from "./SectionQHSSE/SectionQHSSE";
import SectionQualite from "./SectionQualite/SectionQualite";

const Nous = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div>

            <HeaderNous></HeaderNous>
            <SectionEquipe></SectionEquipe>
            <SectionQHSSE></SectionQHSSE>
            <SectionQualite></SectionQualite>

        </div>

    );

}

export default Nous;