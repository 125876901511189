import React, { useEffect, useState } from "react";
import "./SearchBar.css";
import Fuse from "fuse.js";
import SearchBarPhysic from "./SearchBarPhysic/SearchBarPhysic";
import { useNavigate } from 'react-router-dom';


interface SearchResult {
    title: string;
    details: string;
    url: string;
  }
  
const searchResults: SearchResult[] = [
    {title:"collection médicale", details: "Tenue infirmier", url: "/collectionmedicale" },
    {title:"collection médicale", details: "Tunique médicale", url: "/collectionmedicale" },
    {title:"collection médicale", details: "Blouse docteur", url: "/collectionmedicale" },

    {title:"collection industrielle", details: "Combinaison unie / double fermeture", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "Gilets simple/multi-poche", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "Gilets de sauvetage", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "veste de travail", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "T-shirt haute visibilité", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "Chaussures de sécurité", url: "/collectionindustrielle" },

    {title:"collection vêtements de pluie", details: "Imperméable", url: "/collectionpluie" },
    {title:"collection vêtements de pluie", details: "poncho", url: "/collectionpluie" },
    {title:"collection vêtements de pluie", details: "Bottes en PVC", url: "/collectionpluie" },
    {title:"collection vêtements de pluie", details: "Bottes de pluie", url: "/collectionpluie" },


    {title:"collection force et armée", details: "police", url: "/collectionforce" },
    {title:"collection force et armée", details: "gendarme", url: "/collectionforce" },
    {title:"collection force et armée", details: "sécurité", url: "/collectionforce" },
    {title:"collection force et armée", details: "militaire", url: "/collectionforce" },
    {title:"collection force et armée", details: "force  armée", url: "/collectionforce" },
    {title:"collection force et armée", details: "policier", url: "/collectionforce" },
    {title:"collection force et armée", details: "garde", url: "/collectionforce" },
    {title:"collection force et armée", details: "gardien", url: "/collectionforce" },
    {title:"collection force et armée", details: "ranger", url: "/collectionforce" },
    {title:"collection force et armée", details: "botte", url: "/collectionforce" },

    {title:"collection bien-être et esthétique", details: "Kimono", url: "/collectionesthetique" },
    {title:"collection bien-être et esthétique", details: "Blouse à col mao", url: "/collectionesthetique" },
    {title:"collection bien-être et esthétique", details: "esthétique", url: "/collectionesthetique" },

    {title:"collection hotel et restaurant", details: "Chemise de travail", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "Ensemble veste et pantalon de bureau", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "Ensemble veste etjupe de bureau", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "Uniforme femme de ménage", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "Tenues chef cuisinier", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "Tabliers de cuisine", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "Tabliers de chef", url: "/collectionhotel" },

    {title:"collection office", details: "Chemise élégante femme", url: "/collectionoffice" },
    {title:"collection office", details: "Chemise homme", url: "/collectionoffice" },
    {title:"collection office", details: "Polo personnalisable", url: "/collectionoffice" },
    {title:"collection office", details: "T-shirt personnalisable", url: "/collectionoffice" },
    {title:"collection office", details: "doudoune", url: "/collectionoffice" },
    {title:"collection office", details: "Pull et Hoodie personnalisable", url: "/collectionoffice" },
    {title:"collection office", details: "Short et pantalon personnalisable", url: "/collectionoffice" },
    {title:"collection office", details: "Maillot sport", url: "/collectionoffice" },

    {title:"Prêt-à-porter", details: "Chemise à col asymétrique", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Chemise lambahoany", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Chemise classique", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Chemise jaune cintrée", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Combinaison kaki évasée", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Haut sans manches", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Haut à manches bouffantes", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Ensemble tailleur short", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Robe unie", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Chemise élégante femme", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Chemise homme", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "doudoune", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Chemise de travail", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Ensemble veste et pantalon de bureau", url: "/pretaporter" },
    {title:"Prêt-à-porter", details: "Ensemble veste etjupe de bureau", url: "/pretaporter" },

    {title:"collection médicale", details: "charlotte (à usage unique)", url: "/collectionmedicale" },
    {title:"collection médicale", details: "Gant nitril (Non poudré)", url: "/collectionmedicale" },
    {title:"collection médicale", details: "Calot médical", url: "/collectionmedicale" },
    {title:"collection médicale", details: "Surblouse jetable", url: "/collectionmedicale" },
    {title:"collection médicale", details: "Surchaussure", url: "/collectionmedicale" },

    {title:"collection bien être et esthétique", details: "Cape coiffure", url: "/collectionesthetique" },
    {title:"collection bien être et esthétiquee", details: "Tablier coiffeur", url: "/collectionesthetique" },

    {title:"collection hotel et restaurant", details: "Serviette de table ", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "Serviette de toilette ", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "chaussure de cuisine ", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "oreiller / coussins", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "couettes", url: "/collectionhotel" },
    {title:"collection hotel et restaurant", details: "Linges de cuisine", url: "/collectionhotel" },

    {title:"collection office", details: "Tote bag", url: "/collectionoffice" },
    {title:"collection office", details: "sac", url: "/collectionoffice" },
    {title:"collection office", details: "chapeau", url: "/collectionoffice" },
    {title:"collection office", details: "bob", url: "/collectionoffice" }, 
    {title:"collection office", details: "casquette", url: "/collectionoffice" },
    {title:"collection office", details: "casquette subsahrienne", url: "/collectionoffice" },

    {title:"collection industrielle", details: "tablier soudeur", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "manchette soudeur", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "couvre bottes de soudeur", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "lunette de protection", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "visière de protection", url: "/collectionindustrielle" },
    {title:"collection industrielle", details: "casque de protection", url: "/collectionindustrielle" },

    {title:"personnalisation", details: "personnalisation", url: "/personnalisation" },
    {title:"personnalisation", details: "couleur", url: "/personnalisation" },
    {title:"personnalisation", details: "code couleur", url: "/personnalisation" },
    {title:"personnalisation", details: "personnaliser", url: "/personnalisation" },

    {title:"livraison et retour", details: "livraison", url: "/livraison" },
    {title:"livraison et retour", details: "livrer", url: "/livraison" },
    {title:"livraison et retour", details: "carton", url: "/livraison" },
    {title:"livraison et retour", details: "camion", url: "/livraison" },
    {title:"livraison et retour", details: "étranger", url: "/livraison" },
    {title:"livraison et retour", details: "province", url: "/livraison" },
    {title:"livraison et retour", details: "ville", url: "/livraison" },
    {title:"livraison et retour", details: "périphérique", url: "/livraison" },

    {title:"vêtement de sécurité", details: "vêtement de sécurité", url: "/cataloguesecurite" },
    {title:"vêtement de sécurité", details: "sécurité", url: "/cataloguesecurite" },

    {title:"vêtement de travail", details: "vêtement de travail", url: "/cataloguetravail" },
    {title:"vêtement de travail", details: "travail", url: "/cataloguetravail" },

    {title:"accessoires", details: "accessoires", url: "/catalogueaccessoire" },

    {title:"contact", details: "contact", url: "/contact" },
    {title:"contact", details: "contactez-nous", url: "/contact" },
    {title:"contact", details: "contacter", url: "/contact" },
    {title:"contact", details: "formulaire", url: "/contact" },
    {title:"contact", details: "mail", url: "/contact" },

    {title:"A propos", details: "nous", url: "/nous" },
    {title:"A propos", details: "à propos", url: "/nous" },
    {title:"A propos", details: "qui sommes-nous", url: "/nous" },
    {title:"A propos", details: "titanium22", url: "/nous" },

];

const SearchBar = () => {
  
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState<SearchResult[]>([]);
    const fuse = new Fuse(searchResults, {
      keys: ['title', 'details'],
      threshold: 0.3,
    });
    const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      const results = fuse.search(searchQuery);
      setSuggestions(results.map((result) => result.item));
      setIsOpen(!!searchQuery); // ouvre la liste déroulante si la recherche n'est pas vide
    }, [searchQuery]);
  
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    };
  
    const handleSuggestionClick = (result: SearchResult) => {
      navigate(result.url);
    };

    const handleDocumentClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
            if (!target.closest('#search, #results')) {
            setIsOpen(false); // ferme la liste déroulante si l'utilisateur clique ailleurs
            }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
          document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return(

        <div>
         
          <SearchBarPhysic

              searchQuery = {searchQuery}
              handleSearch = {handleSearch}
              isOpen = {isOpen}
              suggestions = {suggestions}
              handleSuggestionClick = {handleSuggestionClick}

          ></SearchBarPhysic>

        </div>

    );

}

export default SearchBar;