import React, { useEffect } from "react";
import "./ConditionVente.css";

const ConditionVente = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div className="container ConditionVente">

            <p className="h1 text-center py-5">Conditions générales de vente.</p>

            <div className="container d-flex flex-column">

                <p className="h2 my-3 poliTitle">Objet</p>
                <p className="fs14 bold500">Les présentes conditions régissent les ventes de vêtements de travail effectuées par Titanium22.</p>

                <p className="h2 my-3 poliTitle">Commandes</p>
                <p className="fs14 bold500">Les commandes peuvent être passées sur notre site web ( Pour bientôt ). Le paiement peut être effectué par carte bancaire ou via des services de paiement en ligne sécurisés.</p>

                <p className="h2 my-3 poliTitle">Prix</p>
                <p className="fs14 bold500">Les prix sont indiqués en Ariary ( MGA ) et incluent les taxes. Les frais de livraison sont précisés lors de la commande.</p>

                <p className="h2 my-3 poliTitle">Livraison</p>
                <p className="fs14 bold500">Nous livrons dans les délais indiqués lors de la commande. En cas de retard, nous vous informerons dès que possible.</p>

                <p className="h2 my-3 poliTitle">Rétractation et Retours </p>
                <p className="fs14 bold500">Vous disposez de 3 jours pour retourner un produit. Les frais de retour sont à votre charge sauf en cas de produit défectueux.</p>

                <p className="h2 my-3 poliTitle">Garanties et Responsabilités</p>
                <p className="fs14 bold500">Nos produits bénéficient d'une garantie de 2semaines, sans garantir le malfaçon de matières premières (teintes délavées, etc.). <br/> Notre responsabilité est limitée au montant de la commande.</p>

                <p className="h2 my-3 poliTitle">Litiges </p>
                <p className="fs14 bold500 mb-5">En cas de litige, vous pouvez contacter notre service client. À défaut d'accord amiable, les tribunaux de Antananarivo seront compétents.</p>

            </div>

        </div>

    );

}

export default ConditionVente;