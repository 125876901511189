import React from "react";
import "./HeaderLivraison.css"
import mdg from "../../../Assets/ressources/logo/mdg.jpg";
import truckdelivery from "../../../Assets/animations/delivery.jpg";
import mainveste from "../../../Assets/ressources/sections/mainveste.jpg";

const HeaderLivraison = () => {

    return(

        <div className="container-fluid HeaderLivraison">

            <div className="container">

                <div className="row entete">

                    {/*PRESENTATION*/}
                    <div className="col-12 col-md-7 mb-5">

                        <div className="container">

                            <div className="d-flex gapImgMdg">
                                <h1 className="h1 text-white bold600 mb-3">Livraison partout dans le pays</h1>
                                <div className="containImgMdgLivraison">
                                    <img src={mdg} alt="Madagascar Flag" className="imgMdg"/>
                                </div>
                            </div>

                            <p className="text-white fs14 bold500 mb-5">Nous avons des taris de livraisons pour chaque secteur de Madagascar.</p>

                            {/*Truck animation*/}
                            <div className="container d-none d-md-block">

                                <div className="moveTruck">

                                    <div className="container truckContain">

                                        <img src={truckdelivery} alt="camion" className="img-fluidTruckContain" />

                                    </div>

                                </div>

                            </div>

                            {/*Truck image mobile*/}
                            <div className="container d-block d-md-none">

                                <div className="container truckContain">

                                    <img src={truckdelivery} alt="camion" className="img-fluidTruckContain" />

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*IMAGE*/}
                    <div className="d-none d-md-block col-12 col-md-5">

                        <div className="container">

                            <div className="headerlivraisonImgContain">

                                <img src={mainveste} alt="veste" className="img-fluidheaderlivraisonImgContain" />

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    );

}

export default HeaderLivraison;