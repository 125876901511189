import React from "react";

const Formulaire = (props:any) => {

    return(

        <div>

            {/*Titre*/}     
            <h1 className="h1 mb-5">Formulaire de contact</h1>

            {/*Formulaire*/}
            <form onSubmit={props.handleSubmit} className="row">
                
                {/*Nom et prénom*/}
                <div className="col-md-6 mb-3">
                    
                    <label htmlFor="name" className="form-label">Nom</label>
                    <input type="text" name="name" id="name" className="form-control rounded-5" value={props.nom} onChange={(e) => props.setNom(e.target.value)} />

                </div>

                <div className="col-md-6 mb-3">

                    <label htmlFor="name" className="form-label">Prénom</label>
                    <input type="text" name="name" id="name" className="form-control rounded-5" value={props.prenom} onChange={(e) => props.setPrenom(e.target.value)} />

                </div>
                
                {/*EMAIL et Numéro*/}
                <div className="col-md-8 mb-3">

                    <label htmlFor="email" className="form-label">Votre Email</label>
                    <input type="text" className="form-control rounded-5" value={props.email} onChange={(e) => props.setEmail(e.target.value)}/>

                </div>

                <div className="col-md-4 mb-3">

                    <label htmlFor="name" className="form-label">Votre téléphone</label>
                    <input type="text" name="name" id="name" className="form-control rounded-5" value={props.phone} onChange={(e) => props.setPhone(e.target.value)} />

                </div>
                
                {/*Nom de la société*/}
                <div className="col-12 mb-3">

                    <label htmlFor="name" className="form-label">Nom de la société</label>
                    <input type="text" name="name" id="name" className="form-control rounded-5" value={props.societe} onChange={(e) => props.setSociete(e.target.value)}/>

                </div>
                
                {/*MESSAGES*/}
                <div className="col-12 mb-3">

                    <label htmlFor="name" className="form-label">Message</label>
                    <input name="name" id="name" className="form-control rounded-5" value={props.message} onChange={(e) => props.setMessage(e.target.value)}/>

                </div>

                {/*Bouton*/}
                <div className="col-12 col-md-6 mb-5">

                    <button type="submit" className="btnPerso btnsend">Envoyer</button>

                </div>

            </form>

        </div>

    );

}

export default Formulaire;