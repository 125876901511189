import React from "react";
import "./SectionSupportClient.css";
import supportclient from "../../../Assets/ressources/sections/femme.jpg";
import bgsupport from "../../../Assets/ressources/logo/call.jpg";

const SectionSupportClient = () => {

    return(

        <div className="container-fluid SectionSupportClient mb-5">

            {/*SUPPORT CLIENT CONTENT*/}
            <div className="container contentSupportClient">

                <div className="row catprodText">

                    {/*TEXTE SUPPORT CLIENT*/}
                    <div className="col-12 col-md-6 col-lg-5">

                        <div className="container">

                            <div className="row textSupportClient">

                                {/*TITRE SUPPORT CLIENT*/}
                                <div className="col-12">
                                    <p className="h1 bold600">Nos supports clients</p>
                                </div>

                                <div className="col-12">
                                    <p className="fs14 bold600 mt-3 mb-5">Nous avons un service dédié aux conseils pour chaque client avant leur commande pour mieux les orienter dans leur achat et pour qu'ils en soient gagnants financièrement.</p>
                                </div>

                            </div>

                        </div>

                    </div>

                    {/*IMAGE SUPPORT CLIENT*/}
                    <div className="col-12 col-md-6 col-lg-7 mb-5">

                        <div className="container imgSupportClient">
                            <img src={supportclient} alt="femme" className="imgSupportClientContent"/>
                        </div>

                    </div>

                </div>

            </div>

            {/*ICONE SUPPORT BACKGROUND en XXL*/}
            <div className="container d-none d-xxl-block">
                <div className="bgsupport1">
                    <img src={bgsupport} alt="sacs" className="img-fluid"/>
                </div>
                <div className="bgsupport2">
                    <img src={bgsupport} alt="sacs" className="img-fluid"/>
                </div>
                <div className="bgsupport4">
                    <img src={bgsupport} alt="sacs" className="img-fluid"/>
                </div>
            </div>

        </div>
    );

}

export default SectionSupportClient;