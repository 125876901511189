import React from "react";
import "./SectionVetTravail.css";
import femmeverte from "../../../Assets/ressources/sections/femmeverte.jpg";
import hommecostume from "../../../Assets/ressources/sections/hommecostume.jpg";
import bag from "../../../Assets/ressources/logo/sac.jpg";
import { NavLink } from "react-router-dom";

const SectionVetTravail = () => {

    return(

        <div className="container-fluid SectionVetTravail">
            
            {/*CONTENT*/}
            <div className="container">

                <div className="container row catprodText">

                    {/*TEXTE*/}
                    <div className="col-12 col-md-6 col-lg-5">

                        <div className="row">

                            {/*GRAND TITRE*/}
                            <div className="col-12 mb-2">

                                <div className="d-flex">
                                    <p className="h1 bold600">Vêtement de travail</p>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="55" height="55"
                                        viewBox="0 0 24 24"
                                        className="iconText"
                                     >
                                        <path fill="#0047AB" d="M4 16.577v1.808q0 .23.192.423t.423.192h14.77q.23 0 .423-.192t.192-.423v-1.808zM8 7V5.384q0-.69.463-1.152t1.153-.463h4.769q.69 0 1.153.463T16 5.384V7h3.385q.69 0 1.152.463T21 8.616v9.769q0 .69-.463 1.153T19.385 20H4.615q-.69 0-1.152-.462T3 18.384V8.616q0-.691.463-1.153T4.615 7zm-4 7.423h16V8.615q0-.23-.192-.423T19.385 8H16v1.116q0 .213-.143.356q-.143.144-.357.144t-.357-.144T15 9.116V8H9v1.116q0 .213-.143.356q-.143.144-.357.144t-.357-.144T8 9.116V8H4.616q-.231 0-.424.192T4 8.616zM9 7h6V5.384q0-.23-.192-.423t-.423-.192h-4.77q-.23 0-.423.192T9 5.384zM4 18.385V8v1.616V8v1.616V8h.616q-.231 0-.424.192T4 8.616zq0 .23.192.423t.423.192H4z"/>
                                    </svg>
                                </div>

                            </div>
                            
                            {/*DESCRIPTION*/}
                            <div className="col-12">
                                <p className="fs14 bold500">Ces vêtements de travail sont conçus pour offrir confort et fonctionnalité au quotidien, adaptés à divers environnements professionnels. Ils allient praticité et aisance, permettant de travailler efficacement tout en répondant aux besoins spécifiques de chaque poste, sans compromettre le style ou la durabilité.</p>
                            </div>

                            {/*BOUTON*/}
                            <div className="col-12 mt-3">
                                <NavLink to="/cataloguetravail" className="btnPerso">Voir nos produits</NavLink>
                            </div>

                        </div>

                    </div>

                    {/*IMAGES*/}
                    <div className="col-12 col-md-6 col-lg-7">

                        <div className="container">

                            <div className="d-flex flex-column mfordoubleimages">

                                {/*IMAGE1*/}
                                <div className="container wforvetement">
                                    <img src={femmeverte} alt="femme" className="img-fluidforvetement" />
                                </div>

                                {/*IMAGE2*/}
                                <div className="container wforvetement transformvetement2 d-none d-lg-block">
                                    <img src={hommecostume} alt="femme" className="img-fluidforvetement" />
                                </div>

                            </div>          

                        </div>

                    </div>

                </div>

            </div>

            {/*ICONE BAG BACKGROUND en XXL*/}
            <div className="container d-none d-xxl-block">
                <div className="bgsafe1">
                    <img src={bag} alt="safe" className="img-fluid"/>
                </div>
                <div className="bgsafe2">
                    <img src={bag} alt="safe" className="img-fluid"/>
                </div>
                <div className="bgsafe3">
                    <img src={bag} alt="safe" className="img-fluid"/>
                </div>
                <div className="bgsafe4">
                    <img src={bag} alt="safe" className="img-fluid"/>
                </div>
            </div>

        </div>

    );

}

export default SectionVetTravail;