import React, { useEffect } from "react";
import Headerhome from "./HeaderHome/Headerhome";
import SectionCategorie from "./SectionCategorie/SectionCategorie";
import SectionReference from "./SectionReference/SectionReference";
import SectionTemoignage from "./SectionTemoignage/SectionTemoignage";
import SectionSupportClient from "./SectionSupportClient/SectionSupportClient";

const Accueil = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div>
            <Headerhome></Headerhome>
            <SectionCategorie></SectionCategorie>
            <SectionReference></SectionReference>
            <SectionTemoignage></SectionTemoignage>
            <SectionSupportClient></SectionSupportClient>
        </div>

    );

}

export default Accueil;