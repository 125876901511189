import React, { useEffect } from "react";
import "./MentionLegale.css";

const MentionLegale = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div className="container MentionLegale">

            <p className="h1 text-center py-5">Mentions légales.</p>

            <div className="container d-flex flex-column">

                <p className="h2 my-3 poliTitle">Identification de la Société :</p>

                <div className="container d-flex flex-column">
                    <p className="fs14"> <span className="bold600">Nom de la société :</span> Titanium22 </p>
                    <p className="fs14"> <span className="bold600">Forme juridique :</span> SARL </p>
                    <p className="fs14"> <span className="bold600">Adresse du siège social :</span>  Ambohimandroso Itaosy</p>
                    <p className="fs14"> <span className="bold600">Numéro de téléphone :</span>  +261 34 33 527 22 - +261 32 02 487 70 </p>
                    <p className="fs14"> <span className="bold600">Adresse email:</span> titanium22@groupeuser.mg</p>
                </div>

                <p className="h2 my-3 poliTitle">Directeur de la Publication :</p>
                <p className="fs14 bold600">Ny Lantosoa RAZAFINDRAKOTO</p>

                <p className="h2 my-3 poliTitle">Hébergement du Site :</p>
                <div className="container d-flex flex-column">

                    <p className="fs14"> <span className="bold600">Hébergeur :</span> ****** </p>
                    <p className="fs14"> <span className="bold600">Adresse :</span> ****** </p>

                </div>

                <p className="h2 my-3 poliTitle">Propriété Intellectuelle :</p>
                <p className="fs14 bold600">Le contenu de ce site est la propriété de Titanium22. Toute reproduction est interdite sans autorisation préalable.</p>

                <p className="h2 my-3 poliTitle">Limitation de Responsabilité :</p>
                <p className="fs14 bold600">Les informations sur ce site sont fournies "telles quelles" sans garantie d'exactitude. Titanium22 ne peut être tenue responsable des erreurs ou omissions.</p>
                
                <p className="h2 my-3 poliTitle">Liens Hypertextes :</p>
                <p className="fs14 bold600 mb-5">Notre site peut contenir des liens vers des sites externes. Nous ne sommes pas responsables du contenu de ces sites.</p>

            </div>

        </div>
  
    );

}

export default MentionLegale;