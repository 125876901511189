import React from "react";
import "./SectionAccessoire.css";
import casquerouge from "../../../Assets/ressources/sections/casquerouge.jpg";
import gant from "../../../Assets/ressources/sections/gant.jpg";
import casque from "../../../Assets/ressources/logo/casque.jpg";
import { NavLink } from "react-router-dom";

const SectionAccessoire = () => {

    return(

        <div className="container-fluid SectionAccessoire">

            <div className="container">

                {/*CONTENT*/}
                <div className="container row catprodText">

                    {/*TEXTE*/}
                    <div className="col-12 col-md-6 col-lg-5">
                    
                        <div className="row">

                            {/*GRAND TITRE*/}
                            <div className="col-12 mb-2">

                                <div className="d-flex">

                                    <p className="h1 bold600">Accessoires</p>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="55" height="55" 
                                        viewBox="0 0 24 24"
                                        className="iconText"
                                    >
                                        <path fill="#0047AB" d="M1.916 20.192v-1.646q0-.652.348-1.165t.982-.831q1.217-.611 2.702-.985q1.485-.373 2.968-.373t2.967.373t2.702.985q.634.317.982.83q.349.514.349 1.166v1.646zm1-1h12v-.646q0-.41-.234-.673t-.574-.446q-.939-.488-2.351-.861q-1.413-.374-2.842-.374t-2.841.374t-2.351.861q-.34.183-.574.446q-.234.264-.234.673zm6-6.23q-1.285 0-2.143-.858t-.857-2.258h-.212q-.168 0-.276-.108q-.109-.11-.109-.276t.109-.276t.276-.109h.211q.039-.76.32-1.38q.28-.622.911-1.185v.95q0 .167.109.276q.109.108.276.108t.276-.108t.108-.276V6.15q.206-.075.466-.132q.26-.056.535-.056t.534.056t.466.132v1.312q0 .167.108.276q.109.108.276.108t.276-.108t.109-.276v-.95q.65.563.92 1.184q.272.621.31 1.381h.212q.167 0 .276.109q.108.108.108.276t-.108.276t-.276.108h-.212q0 1.4-.857 2.258q-.858.858-2.143.858m0-1q.864 0 1.432-.569q.568-.568.568-1.432h-4q0 .864.569 1.432t1.431.569m7.539 2.884l-.073-.711q-.189-.05-.394-.161t-.349-.255l-.661.289l-.385-.67l.589-.422q-.039-.114-.039-.208v-.406q0-.085.039-.217l-.589-.423l.385-.67l.661.289q.139-.139.346-.252q.208-.113.397-.163l.073-.712h.769l.073.712q.189.05.396.163t.347.252l.661-.289l.385.67l-.589.422q.039.133.039.218v.406q0 .094-.039.207l.589.424l-.385.669l-.662-.289q-.144.144-.349.255q-.205.11-.393.16l-.073.712zm.384-1.442q.377 0 .64-.263q.264-.264.264-.641t-.263-.64t-.64-.264t-.641.264t-.264.64t.264.64t.64.264m2.031-3.981l-.085-.78q-.282-.056-.595-.227q-.312-.17-.5-.416l-.743.312l-.43-.739l.657-.48q-.05-.145-.07-.297q-.019-.152-.019-.296t.02-.296t.069-.296l-.658-.481l.431-.738l.742.311q.189-.246.502-.416q.312-.17.595-.226l.084-.781h.862l.084.78q.283.057.595.227q.313.17.501.416l.743-.312l.43.739l-.657.48q.05.145.07.297q.018.152.018.296t-.019.296t-.069.296l.658.481l-.431.739L20.912 8q-.189.246-.501.416q-.313.17-.595.226l-.085.781zm.431-1.557q.583 0 .974-.392q.391-.391.391-.974t-.391-.974t-.974-.391t-.974.391t-.392.974t.392.974t.974.392M8.915 19.192"/>
                                    </svg>

                                </div>

                            </div>

                            {/*DESCRIPTION*/}
                            <div className="col-12">
                                <p className="fs14 bold500">Ces accessoires complètent vos tenues de sécurité et de travail en offrant une protection accrue ou en ajoutant des fonctionnalités pratiques. Ils sont conçus pour améliorer votre confort et votre efficacité, tout en répondant aux exigences spécifiques de vos activités professionnelles.</p>
                            </div>

                            {/*BOUTON*/}
                            <div className="col-12 mt-3">
                                <NavLink to="/catalogueaccessoire" className="btnPerso">Voir nos produits</NavLink>
                            </div>

                        </div>

                    </div>

                    {/*IMAGES*/}
                    <div className="col-12 col-md-6 col-lg-7">

                        <div className="container">

                            <div className="d-flex flex-column mfordoubleimages">

                                {/*IMAGE1*/}
                                <div className="container wforvetement">
                                    <img src={casquerouge} alt="femme" className="img-fluidforvetement" />
                                </div>

                                {/*IMAGE2*/}
                                <div className="container wforvetement transformvetement2 d-none d-lg-block">
                                    <img src={gant} alt="femme" className="img-fluidforvetement" />
                                </div>

                            </div>          

                        </div>

                    </div>

                </div>

                {/*ICONE CASQUE BACKGROUND en XXL*/}
                <div className="container d-none d-xxl-block">
                    <div className="bgsafe1 bgcasquetaille deplacecasque1">
                        <img src={casque} alt="casque" className="img-fluid"/>
                    </div>
                    <div className="bgsafe2 bgcasquetaille">
                        <img src={casque} alt="casque" className="img-fluid"/>
                    </div>
                    <div className="bgsafe3 bgcasquetaille">
                        <img src={casque} alt="casque" className="img-fluid"/>
                    </div>
                    <div className="bgsafe4 bgcasquetaille">
                        <img src={casque} alt="casque" className="img-fluid"/>
                    </div>
                </div>

            </div>

        </div>

    );

}

export default SectionAccessoire;