import React from "react";
import "./HeaderProduits.css";
import mdg from "../../../Assets/ressources/logo/mdg.jpg";
import { NavLink } from "react-router-dom";
import ceintre from "../../../Assets/ressources/sections/ceintre.jpg";

const HeaderProduits = () => {

    return(

        <div className="container-fluid HeaderProduits">

            <div className="container">

                <div className="row entete">

                    {/*COTE PRESENTATION TEXTE*/}
                    <div className="col-12 col-lg-6 gridHeight mb-5">

                        <div className="container">

                            <div className="d-flex gapImgMdg">

                                <h1 className="h1 text-white bold600 mb-3">Explorez notre large gamme de vêtements uniques</h1>

                                <div className="containImgMdgProduit">
                                    <img src={mdg} alt="Madagascar Flag" className="imgMdg"/>
                                </div>

                            </div>
                            <p className="text-white fs14 bold400 mb-5">Faîtes-vous plaisir parmi des vêtements de sécurité, vêtements de travail et accessoires.</p>
                            <NavLink to="/personnalisation" className="btnPerso">Besoin de personnaliser</NavLink>

                        </div>

                    </div>

                     {/*COTE PRESENTATION IMAGE*/}
                    <div className="d-none d-lg-block col-12 col-lg-6">

                        <div className="container">

                            <div className="enteteCeintreImg">
                                <img src={ceintre} alt="ceintre" className="imgFill"/>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    );

}

export default HeaderProduits;