import React from "react";
import "./Headerhome.css";
import imgPresentation from "../../../Assets/ressources/sections/woman.jpg";
import newMonth from "../../../Assets/ressources/catalogue/Vtravail/chotel/chefcuisinier.jpg";
import bestSell from "../../../Assets/ressources/catalogue/Vsecurite/cindustriel/vesteTravail.jpg";
import mdg from "../../../Assets/ressources/logo/mdg.jpg";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';


const Headerhome = () => {

    return(

       <div className="container-fluid Headerhome">

             {/*Carte*/}
             <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                slidesPerView={1}
                spaceBetween={300}
                autoplay={{delay: 2500}}
                loop={true}
                rewind={false}
                speed={1200}
                pagination={{ clickable: true }}
                className="container-fluid entete swiper-custom3"

            >   

                {/*PRESENTATION TITANIUM 22*/}
                <SwiperSlide>

                    <div className="container">

                        <div className="row">

                            {/*COTE PRESENTATION TEXTE*/}
                            <div className="col-12 col-md-6 col-lg-8 gridHeight">
                                <div className="container">

                                    <div className="d-flex gapImgMdg">
                                        <h1 className="h1 text-white bold600 mb-3">« Titanium22 vous habille! »</h1>
                                        <div className="containImgMdg containImgMdgHome">
                                            <img src={mdg} alt="Madagascar Flag" className="imgMdg"/>
                                        </div>
                                    </div>
                                    
                                    <p className="text-white fs14 bold400 mb-5">Que ce soit au travail, à la maison ou partout dans le monde.</p>
                                    <NavLink to="/produits" className="btnPerso">Commencer</NavLink>
                                </div>
                            </div>

                            {/*COTE PRESENTATION IMAGE*/}
                            <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                                <div className="container">
                                    <div className="entetePresImg">
                                        <img src={imgPresentation} alt="femme" className="imgContain"/>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </SwiperSlide>

                {/*NOUVEAUTE DU MOIS*/}
                <SwiperSlide>

                    <div className="container">

                        <div className="row">

                            {/*COTE PRESENTATION TEXTE*/}
                            <div className="col-12 col-md-6 col-lg-8 gridHeight">
                                <div className="container">

                                    <div className="d-flex gapImgMdg">
                                        <p className="h1 text-white bold600 mb-3">« Nouveautés du mois »</p>
                                        <div className="containImgMdg containImgMdgNew2">
                                            <img src={mdg} alt="Madagascar Flag" className="imgMdg"/>
                                        </div>
                                    </div>

                                    <p className="fs14 bold400 fs14 text-white">Découvrez notre nouveauté du mois ! Profitez de nos dernières créations tendance, <br/> conçues pour vous offrir style et confort.</p>
                                
                                </div>
                            </div>

                            {/*COTE PRESENTATION IMAGE*/}
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="container">
                                    <div className="enteteNewImg2">
                                        <img src={newMonth} alt="nouveaute" className="imgContain"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </SwiperSlide>

                {/*MEILLEUR VENTE*/}
                <SwiperSlide>

                    <div className="container">

                        <div className="row">

                            {/*COTE PRESENTATION TEXT*/}
                            <div className="col-12 col-md-6 col-lg-8 gridHeight">
                                <div className="container">

                                    <div className="d-flex gapImgMdg">
                                        <p className="h1 text-white bold600 mb-3">« Notre meilleure vente »</p>
                                        <div className="containImgMdg">
                                            <img src={mdg} alt="Madagascar Flag" className="imgMdg"/>
                                        </div>
                                    </div>
                                    
                                    <p className="fs14 bold400 text-white">Découvrez notre meilleure vente ! Ne manquez pas le produit le plus prisé, <br/> alliant qualité et popularité.</p>
                                </div>
                            </div>

                            {/*COTE PRESENTATION IMAGE*/}
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="container">
                                    <div className="enteteNewImg">
                                        <img src={bestSell} alt="meilleur vente" className="imgContain"/>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </SwiperSlide>
                        

            </Swiper>

       </div>
       
    );

}

export default Headerhome; 