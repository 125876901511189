import React from "react";
import "./SectionReference.css";
import augias from "../../../Assets/ressources/marque/augias.jpg";
import cogeci from "../../../Assets/ressources/marque/cogeci.jpg";
import leader from "../../../Assets/ressources/marque/leader.jpg";
import materauto from "../../../Assets/ressources/marque/materauto.jpg";
import mictsl from "../../../Assets/ressources/marque/mictsl.jpg";
import nutrizaza from "../../../Assets/ressources/marque/nutrizaza.jpg";
import olo from "../../../Assets/ressources/marque/Olo.jpg";
import oti from "../../../Assets/ressources/marque/oti.jpg";
import pnud from "../../../Assets/ressources/marque/pnud.jpg";
import premium from "../../../Assets/ressources/marque/premium.jpg";
import ravinala from "../../../Assets/ressources/marque/ravinala.jpg";
import refrigepeche from "../../../Assets/ressources/marque/refrigepeche.jpg";
import stropicale from "../../../Assets/ressources/marque/Stropicale.jpg";
import terra from "../../../Assets/ressources/marque/Terra.jpg";
import ulys from "../../../Assets/ressources/marque/Ulys.jpg";
import user from "../../../Assets/ressources/marque/user.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const SectionReference = () => {

    return(

        <div className="container-fluid SectionReference">
            
            <div className="">

                <Swiper

                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={5}
                    autoplay={{delay: 2000}}
                    loop={true}
                    speed={3000}
                    className="container text-center pt-5 pb-5 "
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024:{
                            slidesPerView: 4,
                            spaceBetween: 100,
                        },
                        1440:{
                            slidesPerView: 5,
                            spaceBetween: 100,
                        }
                    }} 

                >

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef">
                            <img src={user} alt="logo group user" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef">
                            <img src={materauto} alt="logo materauto" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef">
                            <img src={leader} alt="logo leader price" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef scaleThis2 mt-3">
                            <img src={refrigepeche} alt="logo group refrigepêche" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef">
                            <img src={pnud} alt="logo PNUD" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef pt-4 scaleThis1-5">
                            <img src={augias} alt="logo augias" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef pt-4">
                            <img src={cogeci} alt="logo cogeci" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef pt-4 scaleThis1-5">
                            <img src={mictsl} alt="logo mictsl" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef">
                            <img src={ravinala} alt="logo ravinala" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef pt-4">
                            <img src={premium} alt="logo premium" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef pt-3">
                            <img src={oti} alt="logo oti" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef">
                            <img src={olo} alt="logo olo" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef scaleThis1-5">
                            <img src={stropicale} alt="logo savonnerie" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef pt-2">
                            <img src={terra} alt="logo terra" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef">
                            <img src={ulys} alt="logo ulys" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container ContainImg-fluidRef pt-4">
                            <img src={nutrizaza} alt="logo Nutrizaza" className="img-fluidRef"/>
                        </div>

                    </SwiperSlide>


                </Swiper>

            </div>

        </div>

    );

}

export default SectionReference;