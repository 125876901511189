import React from "react";
import "./SectionPretaPorter.css";
import pabouffante from "../../../../Assets/ressources/catalogue/PPorter/bouffante.jpg";
import pachemise from "../../../../Assets/ressources/catalogue/PPorter/chemiseclassique.jpg";
import pacol from "../../../../Assets/ressources/catalogue/PPorter/colasymetrique.jpg";
import pacombinaisonjaune from "../../../../Assets/ressources/catalogue/PPorter/combinaisonjaune.jpg";
import pakaki from "../../../../Assets/ressources/catalogue/PPorter/kaki.jpg";
import palambahoany from "../../../../Assets/ressources/catalogue/PPorter/lambahoany.jpg";
import parobeuni from "../../../../Assets/ressources/catalogue/PPorter/robeuni.jpg";
import pasansmanche from "../../../../Assets/ressources/catalogue/PPorter/sansmanche.jpg";
import patailleur from "../../../../Assets/ressources/catalogue/PPorter/tailleurshort.jpg";
import chchemise from "../../../../Assets/ressources/catalogue/Vtravail/chotel/vtravail.jpg";
import chvestenoir2 from "../../../../Assets/ressources/catalogue/Vtravail/chotel/ensemblevestepantalon.jpg";
import chvestenoir from "../../../../Assets/ressources/catalogue/Vtravail/chotel/ensemblevestejupebureau.jpg";
import cochemise from "../../../../Assets/ressources/catalogue/Vtravail/coffice/chemiseelegante.jpg";
import codoudoune from "../../../../Assets/ressources/catalogue/Vtravail/coffice/doudoune.jpg";

const SectionPretaPorter = () => {

    return(

        <div className="container-fluid SectionPretaPorter">

            <div className="container">

                <div className="row text-center">   
                    
                    <div className="container d-flex justify-content-center my-5">
                        <p className="h2">Nos vêtements prêt à porter</p>
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="55" height="55" 
                            viewBox="0 0 24 24"
                            className="iconText"
                        >
                            <path fill="#0047AB" d="m7 9.277l-1.789.992q-.292.162-.577.09q-.284-.07-.446-.363l-1.23-2.154q-.162-.292-.09-.586q.07-.295.363-.456L7.98 4h1.211q.283 0 .45.166q.166.167.166.45v.192q0 .882.655 1.537T12 7t1.538-.655t.654-1.537v-.192q0-.283.167-.45q.166-.166.449-.166h1.211l4.75 2.8q.293.162.364.456q.07.294-.09.586l-1.231 2.154q-.162.292-.444.36t-.58-.092L17 9.283v9.948q0 .328-.22.549t-.55.22H7.77q-.33 0-.55-.22T7 19.23zM8 7.6V19h8V7.6l3.062 1.68l1.011-1.73l-4.28-2.538h-.635q-.087 1.255-.98 2.122Q13.285 8 12 8t-2.178-.866t-.98-2.123h-.634L3.927 7.55l1.012 1.73zm4 4.406"/>
                        </svg>
                    </div>
                    

                    {/*Produit pretaporter 2*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">
                        <img src={pacol} alt="chemise" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={pacol} alt="chemise" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe : Semi-ajustée</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manches : Longues avec boutons au col et aux poignet</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col mao ou autre</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    
                    {/*Produit pretaporter 3*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">
                        <img src={palambahoany} alt="lambahoany" className="img-fluidCard img-fluidRescale2"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={palambahoany} alt="lambahoany" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe : Semi-ajustée</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manches : Longues avec boutons au col et aux poignets</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col mao ou autre</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                    {/*Produit pretaporter 7*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={pasansmanche} alt="sansmanche" className="img-fluidCard mt-4"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={pasansmanche} alt="sansmanche" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Matière : Jersey de coton</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe : coupe ajustée</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Encolure : Encolure mao</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manches : sans manches</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*Produit hotel 1*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={chchemise} alt="chemise" className="img-fluidCard img-fluidRescale mt-4"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={chchemise} alt="chemise" className="d-none d-lg-block img-fluidCard2 img-fluidRescale"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles: S, M, L, XL, XXL, 3XL, 4XL</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Drill ou coton pour un confort maximal.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Broderie ou sérigraphie pour votre logo.</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*Produit hotel 2*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={chvestenoir2} alt="veste" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={chvestenoir2} alt="veste" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles: S, M, L, XL, XXL, 3XL, 4XL</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu coton pour un confort maximal</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Style décontracté ou formel, avec deux poches latérales et fermeture à bouton.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Logo : Broderie ou sérigraphie personnalisée.</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*Produit hotel 3*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={chvestenoir} alt="veste" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={chvestenoir} alt="veste" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles: S, M, L, XL, XXL, 3XL, 4XL</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu drill ou coton pour un confort maximal.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Logo : Broderie ou sérigraphie personnalisée.</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                 
                     {/*Produit pretaporter 4*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={pachemise} alt="chemise" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={pachemise} alt="chemise" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe : Semi-ajustée</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manches : Longues avec boutons au col et aux poignets</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col mao ou autre</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                     {/*Produit pretaporter 5*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={pacombinaisonjaune} alt="combinaison" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={pacombinaisonjaune} alt="combinaison" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe : Cintrée</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manches : Longues avec boutons au col et aux poignets</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col classique</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*Produit pretaporter 6*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={pakaki} alt="kaki" className="img-fluidCard"/>
                        
                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={pakaki} alt="kaki" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe : Cintrée</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manches : Longues avec boutons au col et aux poignets</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col classique</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Style : Classe et confortable</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*Produit office 1*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={cochemise} alt="chemise" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={cochemise} alt="chemise" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe semi-ajustée, col mao ou autre.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manche longue ou courte, boutons au col et aux poignets.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisable : Selon vos préférences</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*Produit office 6*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={codoudoune} alt="doudoune" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={codoudoune} alt="doudoune" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu drill, coton, ou polyester avec rembourrage ouate pour un confort maximal.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches.</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Logo : Broderie ou sérigraphie personnalisée.</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*Produit pretaporter 1*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={pabouffante} alt="bouffante" className="img-fluidCard mt-3"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={pabouffante} alt="bouffante" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coton, synthétique, nylon, mousseline</p>

                                        </div>

                                    </div>

                                <div className="col-12">

                                <div className="d-flex">

                                <svg 
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="greenLogo"
                                >
                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                </svg>

                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe : Ajustée</p>

                                </div>

                                </div>

                                <div className="col-12">

                                <div className="d-flex">

                                <svg 
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="greenLogo"
                                >
                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                </svg>

                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manches : Bouffantes</p>

                                </div>

                                </div>

                                <div className="col-12">

                                <div className="d-flex">

                                <svg 
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="greenLogo"
                                >
                                    <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                </svg>

                                <p className="container fs12 bold600 cardtext1024 cardTextMargin">Élégant et moderne.</p>

                                </div>

                                </div>

                                </div>

                            </div>

                        </div>

                    </div> 

                    {/*Produit pretaporter 8*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={patailleur} alt="ensemble" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={patailleur} alt="ensemble" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Veste et short en flanelle</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Manches : Longues</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Motif : Uni</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Coupe : Ajustée et couleur personnalisable</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/*Produit pretaporter 9*/}
                    <div className="container col-12 col-md-6 col-lg-4 swipercardPorter mb-5">

                        <img src={parobeuni} alt="robe" className="img-fluidCard"/>

                        {/*HOVER carte*/}
                        <div className="swipercardVar4P">

                            <div className="d-flex">

                                <img src={parobeuni} alt="robe" className="d-none d-lg-block img-fluidCard2"/>

                                <div className="container row mt-3">

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Ajustée à la poitrine</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Evasée à la taille et aux hanches</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Convient à toutes les morphologies</p>

                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="d-flex">

                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="greenLogo"
                                            >
                                                <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                            </svg>

                                            <p className="container fs12 bold600 cardtext1024 cardTextMargin">Couleur personnalisable</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    );

}

export default SectionPretaPorter;