import React, { useEffect, useState } from "react";
import "./SmallDeviceSearch.css";
import { NavLink, useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar/SearchBar";


const SmallDeviceSearch = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    }

    return(

        <div className="SmallDeviceSearch">

            <div className="container">

                <div className="d-flex justify-content-between pt-3">

                    {/*Bouton retour*/}
                    <NavLink to="#" onClick={handleGoBack}>

                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="32" height="32" 
                            viewBox="0 0 24 24"
                        >
                            <path fill="#fff" d="M16 21.308L6.692 12L16 2.692l1.064 1.064L8.819 12l8.244 8.244z"/>
                        </svg>

                    </NavLink>

                    {/*Barre de recherche*/}
                    <div className="ptn-searchbar">
                        <SearchBar></SearchBar>
                    </div>
                    
                </div>

            </div>

        </div>
        
    );

}

export default SmallDeviceSearch;