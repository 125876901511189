import React, { useEffect } from "react";
import HeaderPersonnalisation from "./HeaderPersonnalisation/HeaderPersonnalisation";
import SectionCodeCouleur from "./SectionCodeCouleur/SectionCodeCouleur";
import SectionFlexibilite from "./SectionFlexibilite/SectionFlexibilite";

const Personnalisation = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div>
          <HeaderPersonnalisation></HeaderPersonnalisation>
          <SectionCodeCouleur></SectionCodeCouleur>
          <SectionFlexibilite></SectionFlexibilite>
        </div>

    );

}

export default Personnalisation;