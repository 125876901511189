import React from "react";

const SearchBarPhysic = (props:any) => {

    return(

        <div>

            <input type="search" value={props.searchQuery} onChange={props.handleSearch} className="form-control form-control2 active" placeholder="Rechercher"/>
                
                {props.isOpen && (

                    <ul id="results">

                        {props.suggestions.map((result:any, index:any) => (

                            <li key={index} onClick={() => props.handleSuggestionClick(result)} className="listSearch">
                                {result.title}
                            </li>

                        ))}

                    </ul>

                )}

        </div>

    );

}

export default SearchBarPhysic;