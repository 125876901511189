import React, { useEffect } from "react";
import SectionFormulaire from "./SectionFormulaire/SectionFormulaire";
import SectionInformation from "./SectionInformation/SectionInformation";

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        
        <div>
            <SectionFormulaire></SectionFormulaire>
            <SectionInformation></SectionInformation>
        </div>

    );

}

export default Contact;