import React from "react";
import "./SectionVetSecurite.css";
import hommerue from "../../../Assets/ressources/sections/hommerue.jpg";
import chantier from "../../../Assets/ressources/sections/chantier.jpg";
import safe from "../../../Assets/ressources/logo/safe.jpg";
import { NavLink } from "react-router-dom";

const SectionVetSecurite = () => {

    return(

        <div className="container-fluid SectionVetSecurite">

            {/*CONTENT*/}
            <div className="container">

                <div className="container row catprodText">

                    {/*TEXTE*/}
                    <div className="col-12 col-md-6 col-lg-5">

                        <div className="row">

                            {/*GRAND TITRE*/}
                            <div className="col-12 mb-2">

                                <div className="d-flex">
                                    <p className="h1 bold600">Vêtement de sécurité</p>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="55" height="55"
                                        viewBox="0 0 24 24"
                                        className="iconText"
                                    >
                                        <path fill="#0047AB" d="M11 15h2v-2.5h2.5v-2H13V8h-2v2.5H8.5v2H11zm1 5.962q-3.014-.895-5.007-3.651T5 11.1V5.692l7-2.615l7 2.615V11.1q0 3.454-1.993 6.21T12 20.963m0-1.062q2.6-.825 4.3-3.3t1.7-5.5V6.375l-6-2.23l-6 2.23V11.1q0 3.025 1.7 5.5t4.3 3.3m0-7.88"/>
                                    </svg>
                                </div>
                                
                            </div>

                            {/*DESCRIPTION*/}
                            <div className="col-12">
                                <p className="fs14 bold500">Ces vêtements sont spécialement conçus pour offrir une protection optimale dans des environnements dangereux ou réglementés. Ils garantissent non seulement une sécurité accrue contre les risques externes, mais aussi une conformité aux normes strictes en vigueur, assurant ainsi la sécurité et la visibilité en toutes circonstances.</p>
                            </div>

                            {/*BOUTON*/}
                            <div className="col-12 mt-3">
                                <NavLink to="/cataloguesecurite" className="btnPerso">Voir nos produits</NavLink>
                            </div>

                        </div>

                    </div>

                    {/*IMAGES*/}
                    <div className="col-12 col-md-6 col-lg-7">

                        <div className="container">

                            <div className="d-flex flex-column mfordoubleimages">

                                {/*Image 1*/}
                                <div className="container wforvetement">
                                    <img src={hommerue} alt="homme" className="img-fluidforvetement"/>
                                </div>

                                {/*Image 2*/}
                                <div className="container wforvetement transformvetement2 d-none d-lg-block">
                                    <img src={chantier} alt="chantier" className="img-fluidforvetement "/>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {/*ICONE SAFE BACKGROUND en XXL*/}
            <div className="container d-none d-xxl-block">
                <div className="bgsafe1">
                    <img src={safe} alt="safe" className="img-fluid"/>
                </div>
                <div className="bgsafe2">
                    <img src={safe} alt="safe" className="img-fluid"/>
                </div>
                <div className="bgsafe3">
                    <img src={safe} alt="safe" className="img-fluid"/>
                </div>
                <div className="bgsafe4">
                    <img src={safe} alt="safe" className="img-fluid"/>
                </div>
            </div>

        </div>

    );

}

export default SectionVetSecurite;