import React from "react";
import "./HeaderPersonnalisation.css";
import paint from "../../../Assets/animations/paint.jpg";
import femmeJaune from "../../../Assets/ressources/sections/femmejaune.jpg";
import mdg from "../../../Assets/ressources/logo/mdg.jpg";

const HeaderPersonnalisation = () =>  {

    return(

        <div className="container-fluid HeaderPersonnalisation">
            
            
            <div className="container">

                <div className="row">

                    {/*Text content */}
                    <div className="col-12 col-lg-7 mb-5">
                        
                        {/*Titre personnalisation*/}
                        <div className="container entete positionTitlePerso d-flex gapImgMdg">

                            <h1 className="h1 text-white bold600 mb-3 h1for320">Personnalisation possible avec Titanium22!</h1>
                            <div className="containImgMdgPerso">
                                <img src={mdg} alt="Madagascar Flag" className="imgMdg"/>
                            </div>

                        </div>

                        {/*Colors anim*/}
                        <div className="container mforpeinture d-none d-lg-block">

                           <div className="ThrowPeinture">

                                <div className="container peintureThrow">
                                    <img src={paint} alt="peinture" className="img-fluidPeinture"/>
                                </div>

                           </div>

                        </div>

                        {/*just colors*/}
                        <div className="container mforpeinture d-block d-lg-none">

                            <div className="container peintureThrow">
                                <img src={paint} alt="peinture" className="img-fluidPeinture"/>
                            </div>

                        </div>

                    </div>

                    {/*COTE PRESENTATION IMAGE*/}
                    <div className="col-12 col-md-5 d-none d-lg-block entete">
                        <div className="container">
                            <div className="entetePresImgPerso">
                                <img src={femmeJaune} alt="femme" className="imgContain"/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    );

}

export default HeaderPersonnalisation;