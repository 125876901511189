import React, { useEffect } from "react";
import "./CatalogueSecurite.css";
import imgSecurite from "../../../Assets/ressources/sections/chantier.jpg";
import SectionIndustrielle from "./SectionIndustrielle/SectionIndustrielle";
import SectionPluie from "./SectionPluie/SectionPluie";

const CatalogueSecurite = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div className="container-fuid CatalogueSecurite">

            {/*Image Entete*/}
            <div className="w-100">

                {/*IMAGE*/}
                <img src={imgSecurite} alt="chantier" className="img-fluidCatalogue"/>
                
                {/*TEXTE*/}
                <p className="display-3 text-center text-white textDisplay container">Vêtements de sécurité</p>
                
            </div>

            {/*Liste des catalogues pour vêtements de sécurité*/}
            <SectionIndustrielle></SectionIndustrielle>
            <SectionPluie></SectionPluie>

        </div>

    );

}

export default CatalogueSecurite;