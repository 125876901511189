import React from "react";
import "./SectionInformation.css";
import imgFuse from "../../../Assets/animations/send.jpg";

const SectionInformation = () => {

    return(

        <div className="container-fluid SectionInformation">

            <div className="container">

                <div className="row">

                    {/*CONTACT  */}
                    <div className="col-12 col-md-8">

                        <div className="row mt-5">   

                            <p className="h1">Informations de contact</p>
                            
                            {/*Mail*/}
                            <div className="col-12 mt-4">

                                <div className="d-flex">

                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="32" height="32" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path fill="#FF00FF" d="M12 21q-1.864 0-3.507-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709t2.859 1.924t1.925 2.857T21 12v.989q0 1.263-.868 2.137T18 16q-.894 0-1.63-.49q-.737-.49-1.09-1.306q-.57.821-1.425 1.308T12 16q-1.671 0-2.835-1.164Q8 13.67 8 12t1.165-2.835T12 8t2.836 1.165T16 12v.989q0 .822.589 1.417T18 15t1.412-.594t.588-1.418V12q0-3.35-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20h5v1zm0-6q1.25 0 2.125-.875T15 12t-.875-2.125T12 9t-2.125.875T9 12t.875 2.125T12 15"/>
                                    </svg>

                                    <p className="fs16 bold500 mx-2">acom.titanium22@groupeuser.mg / <br /> titanium22@groupeuser.mg</p>


                                </div>


                            </div>

                            {/*telephone*/}
                            <div className="col-12 mt-3">

                                <div className="d-flex">

                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="32" height="32" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path fill="#FF00FF" d="M17.93 21q-2.528 0-5.184-1.266t-4.935-3.555t-3.545-4.935T3 6.07q0-.458.3-.763T4.05 5h2.473q.408 0 .712.257q.303.257.411.659L8.142 8.3q.07.42-.025.733t-.333.513l-2.193 2.046q.615 1.117 1.36 2.076t1.59 1.817q.87.87 1.875 1.62q1.003.749 2.203 1.414l2.139-2.177q.244-.263.549-.347t.674-.033l2.104.43q.407.1.661.411t.254.712v2.435q0 .45-.306.75t-.763.3M5.12 10.654l1.92-1.765q.095-.077.124-.212q.03-.135-.01-.25l-.444-2.12q-.038-.153-.134-.23T6.327 6H4.275q-.115 0-.192.077t-.077.192q.029 1.025.321 2.14t.794 2.245m8.45 8.334q1.014.502 2.16.743q1.148.24 2 .257q.115 0 .192-.077T18 19.72v-2.008q0-.153-.077-.25q-.077-.096-.23-.134l-1.85-.379q-.116-.039-.202-.01q-.087.03-.183.125zM16.503 12q-1.866 0-3.185-1.315Q12 9.369 12 7.502t1.316-3.185T16.497 3q1.867 0 3.185 1.316Q21 5.63 21 7.497t-1.315 3.185T16.503 12m-.387-1.616h.769V6.808h-.77zm.384-4.692q.2 0 .35-.15t.15-.35t-.15-.35t-.35-.15t-.35.15t-.15.35t.15.35t.35.15"/>
                                    </svg>

                                    <p className="fs16 bold500 mx-2">+261326534483 / <br /> +261343352722</p>


                                </div>


                            </div>
                            
                            {/*adresse*/}
                            <div className="col-12 mt-3">

                                <div className="d-flex">

                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="32" height="32" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path fill="#FF00FF" d="M9.462 12.654h1.692v-2.365h1.692v2.365h1.692V8.596L12 6.904L9.461 8.596zM12 19.677q2.82-2.454 4.458-4.991t1.638-4.39q0-2.744-1.737-4.53Q14.62 3.981 12 3.981T7.641 5.766t-1.737 4.53q0 1.852 1.638 4.39T12 19.677m0 1.342q-3.525-3.117-5.31-5.814q-1.786-2.697-1.786-4.909q0-3.173 2.066-5.234Q9.037 3 12 3t5.03 2.062q2.066 2.061 2.066 5.234q0 2.212-1.785 4.909q-1.786 2.697-5.311 5.814m0-10.903"/>
                                    </svg>

                                    <p className="fs16 bold500 mx-2 mb-5">Ambohitrarahaba 101 Antananarivo, Madagascar</p>


                                </div>


                            </div>


                        </div>

                    </div>

                    {/*animation fusée*/}
                    <div className="col-12 col-md-4 d-none d-md-block">

                        <div className="moveFuse">

                            <div className="container">

                                <img src={imgFuse} alt="fuse" className="img-fluidFuse" />

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    );

}

export default SectionInformation;