import React from "react";
import "./SectionIndustrielle.css";
import cicombinaisonuni from "../../../../Assets/ressources/catalogue/Vsecurite/cindustriel/combinaisonUni.jpg";
import cigilet from "../../../../Assets/ressources/catalogue/Vsecurite/cindustriel/gilet.jpg";
import cigiletsauvetage from "../../../../Assets/ressources/catalogue/Vsecurite/cindustriel/giletSauvetage.jpg";
import citshirthv from "../../../../Assets/ressources/catalogue/Vsecurite/cindustriel/tshirtHV.jpg";
import civestetravail from "../../../../Assets/ressources/catalogue/Vsecurite/cindustriel/vesteTravail.jpg";
import copantalon from "../../../../Assets/ressources/catalogue/Vsecurite/cindustriel/pantalonperso.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SectionIndustrielle = () => {

        return(

            <div className="container-fluid SectionIndustrielle">

                <div className="container">

                    <div className="row">

                        {/*Titre collection*/}
                        <div className="col-12 mt-5">

                            <div className="d-flex">

                                <p className="h2 bold600">Collection industrielle</p>
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="55" height="55"
                                    viewBox="0 0 24 24"
                                    className="iconTextCm"
                                >
                                    <path fill="#0047AB" d="m18.958 20l-5.552-5.552l1.061-1.061l5.552 5.552zM5.062 20L4 18.939l6.958-6.958l-2.97-2.95l-.546.546l-.948-.967v1.8l-.546.546l-2.621-2.621l.546-.546h1.8l-.961-.943l2.165-2.165q.346-.346.758-.514Q8.046 4 8.512 4q.465 0 .877.167t.757.514L8.5 6.327l1.096 1.096l-.546.546l2.97 2.95l2.557-2.558q-.235-.332-.326-.68t-.091-.764q0-1.225.849-2.074t2.074-.849q.26 0 .5.037q.242.036.477.148L15.989 6.25l1.761 1.761l2.071-2.07q.117.234.151.475q.034.242.034.501q0 1.225-.85 2.074q-.848.85-2.073.85q-.416 0-.764-.089t-.68-.329z"/>
                                </svg>

                            </div>

                        </div>

                        {/*Carte*/}
                        <Swiper 
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={100}
                            slidesPerView={3}
                            navigation
                            pagination={{ clickable: true }}
                            className="container-fluid mt-5 swiper-custom"
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1024:{
                                    slidesPerView: 2,
                                    spaceBetween: 100,
                                },
                                1440:{
                                    slidesPerView: 3,
                                    spaceBetween: 100,
                                }
                            }}
                        >
                            
                            {/*Produit industriel 1*/}
                            <SwiperSlide className="container swipercard">

                                <img src={cicombinaisonuni} alt="combinaison" className="img-fluidCard"/>

                                {/*HOVER carte*/}
                                <div className="swipercardVar4">

                                    <div className="d-flex">

                                        <img src={cicombinaisonuni} alt="combinaison" className="d-none d-lg-block img-fluidCard2"/>

                                        <div className="container row mt-3">

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Drill polycoton ou 100% coton, avec ceinture élastiquée au dos</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Poches soufflées ou plaquées, fermeture à glissière double ou simple.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sécurité : Options de bande réfléchissante grise ou sans bande.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col transformable.</p>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </SwiperSlide>

                            {/*Produit industriel 2*/}
                            <SwiperSlide className="container swipercard">

                                <img src={cigilet} alt="gilet" className="img-fluidCard"/>

                                {/*HOVER carte*/}
                                <div className="swipercardVar4">

                                    <div className="d-flex">

                                        <img src={cigilet} alt="gilet" className="d-none d-lg-block img-fluidCard2"/>

                                        <div className="container row mt-3">

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu crique, ceinture élastiquée au dos</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Avec ou sans poche, fermeture à glissière double ou simple.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sécurité : Bande réfléchissante grise ou sans bande.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col transformable.</p>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </SwiperSlide>

                            {/*Produit industriel 3*/}
                            <SwiperSlide className="container swipercard">

                                <img src={cigiletsauvetage} alt="gilet sauvetage" className="img-fluidCard"/>

                                {/*HOVER carte*/}
                                <div className="swipercardVar4">

                                    <div className="d-flex">

                                        <img src={cigiletsauvetage} alt="gilet sauvetage" className="d-none d-lg-block img-fluidCard2"/>

                                        <div className="container row mt-3">

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu crique, ceinture élastiquée au dos</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Avec ou sans poche, fermeture à glissière double ou simple.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sécurité : Bande réfléchissante grise ou sans bande.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col transformable.</p>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </SwiperSlide>

                            {/*Produit industriel 4*/}
                            <SwiperSlide className="container swipercard">

                                <img src={citshirthv} alt="Tshirt" className="img-fluidCard"/>

                                {/*HOVER carte*/}
                                <div className="swipercardVar4">

                                    <div className="d-flex">

                                        <img src={citshirthv} alt="TShirt" className="d-none d-lg-block img-fluidCard2"/>

                                        <div className="container row mt-3">

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu crique, ceinture élastiquée au dos</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Avec ou sans poche, fermeture à glissière double ou simple.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sécurité : Bande réfléchissante grise ou sans bande.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col transformable.</p>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </SwiperSlide>

                            {/*Produit industriel 5*/}
                            <SwiperSlide className="container swipercard">

                                <img src={civestetravail} alt="Veste Travail" className="img-fluidCard"/>

                                {/*HOVER carte*/}
                                <div className="swipercardVar4">

                                    <div className="d-flex">

                                        <img src={civestetravail} alt="Veste Travail" className="d-none d-lg-block img-fluidCard2"/>

                                        <div className="container row mt-3">

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu crique, ceinture élastiquée au dos</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Avec ou sans poche, fermeture à glissière double ou simple.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Sécurité : Bande réfléchissante grise ou sans bande.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Sérigraphie ou broderie, col transformable.</p>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </SwiperSlide>

                            {/*Produit industriel 6*/}
                            <SwiperSlide className="container swipercard">

                                <img src={copantalon} alt="pantalon" className="img-fluidCard"/>

                                {/*HOVER carte*/}
                                <div className="swipercardVar4">

                                    <div className="d-flex">

                                        <img src={copantalon} alt="pantalon" className="d-none d-lg-block img-fluidCard2"/>

                                        <div className="container row mt-3">

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tailles : S, M, L, XL, XXL, 3XL, 4XL.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Tissu drill ou coton pour un confort maximal.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Personnalisation : Manche courte ou longue, avec ou sans poches.</p>

                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <div className="d-flex">

                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="greenLogo"
                                                    >
                                                        <path fill="#50C878" d="m10.562 15.908l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709"/>
                                                    </svg>

                                                    <p className="container fs12 bold600 cardtext1024 cardTextMargin">Logo : Broderie ou sérigraphie personnalisée.</p>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </SwiperSlide>

                        </Swiper>

                    </div>
                    
                </div>

            </div>

        );

}

export default SectionIndustrielle;