import React, { useEffect } from "react";
import "./Faq.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const Faq = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div className="container-fluid Faq">

            <div className="container">

                <p className="h1 text-center py-4">F.A.Q</p>

                <Swiper

                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={3}
                    autoplay={{delay: 3000}}
                    loop={true}
                    speed={8000}
                    pagination={{ clickable: true }}
                    className="container text-center mt-3 listTemoin pb-5 swiper-custom3"
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024:{
                            slidesPerView: 2,
                            spaceBetween: 100,
                        },
                        1440:{
                            slidesPerView: 3,
                            spaceBetween: 100,
                        }
                    }} 

                >

                    <SwiperSlide>

                        <div className="container row">

                            {/*FAQ*/}
                            <div className="container col-12 cardFAQ">

                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5 bold600"> Comment passer une commande ?</p>
                                    <p className="text-white fs14 bold400 mt-2 mx-5">Pour passer une commande, ajoutez les articles à votre panier et suivez les instructions de paiement.</p>
                                </div>  
                                
                            </div>

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">

                            {/*FAQ*/}
                            <div className="container col-12 cardFAQ">

                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5 bold600"> Quels types de vêtements de travail proposez-vous ?</p>
                                    <p className="text-white fs14 bold400 mt-2 mx-5">Nous proposons une large gamme de vêtements de sécurité et de travail adaptés à divers secteurs.</p>
                                </div>  
                            </div>

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">

                            {/*FAQ*/}
                            <div className="container col-12 cardFAQ">

                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5 bold600"> Puis-je personnaliser mes vêtements de travail ? </p>
                                    <p className="text-white fs14 bold400 mt-2 mx-5">Oui, nous offrons des options de personnalisation pour certains articles.</p>
                                </div>  
                            </div>

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">

                            {/*FAQ*/}
                            <div className="container col-12 cardFAQ">

                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5 bold600">Quels modes de paiement acceptez-vous ?</p>
                                    <p className="text-white fs14 bold400 mt-2 mx-5">Nous acceptons les cartes bancaires et les paiements via Mobilemoney valides à Madagascar.</p>
                                </div>  
                            </div>

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">

                            {/*FAQ*/}
                            <div className="container col-12 cardFAQ">

                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5 bold600">Quels sont les délais de livraison ?</p>
                                    <p className="text-white fs14 bold400 mt-2 mx-5">Les délais de livraison varient entre [nombre] et [nombre] jours ouvrables.</p>
                                </div>  

                            </div>

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">

                            {/*FAQ*/}
                            <div className="container col-12 cardFAQ">

                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5 bold600">Puis-je suivre ma commande ?</p>
                                    <p className="text-white fs14 bold400 mt-2 mx-5">Oui, vous recevrez un email avec un lien de suivi après l'expédition de votre commande.</p>
                                </div>  
                                
                            </div>

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">

                            {/*FAQ*/}
                            <div className="container col-12 cardFAQ">

                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5 bold600">Comment retourner un produit ?</p>
                                    <p className="text-white fs14 bold400 mt-2 mx-5">Contactez notre service client pour obtenir les instructions de retour.</p>
                                </div>  
                                
                            </div>

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>

                        <div className="container row">

                            {/*FAQ*/}
                            <div className="container col-12 cardFAQ">

                                <div className="d-flex flex-column align-items-center">
                                    <p className="text-white text-center mt-5 bold600">Que faire si mon produit est défectueux ?</p>
                                    <p className="text-white fs14 bold400 mt-2 mx-5">Contactez-nous immédiatement et nous vous fournirons un produit de remplacement ou un remboursement.</p>
                                </div>  
                                
                            </div>

                        </div>

                    </SwiperSlide>

                </Swiper>

            </div>

        </div>

    );

}

export default Faq;