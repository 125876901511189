import React from "react";
import "./SectionSav.css";
import imgMain from "../../../Assets/ressources/sections/main.jpg";
import bgbag from "../../../Assets/ressources/logo/bag.jpg";

const SectionSav = () => {

    return(

        <div className="container-fluid SectionSav">

            {/*Content*/}
            <div className="container">

                <div className="container row catprodText">

                    {/*TEXT*/}
                    <div className="col-12 col-md-6 col-lg-7 mt-5">

                        <div className="row">

                            {/*Titre*/}
                            <div className="col-12">

                                <div className="d-flex">

                                    <p className="h1 bold600">Un SAV avec Titanium22!</p>
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="55" height="55" 
                                        viewBox="0 0 24 24"
                                        className="iconTextSav"
                                    >
                                        <path fill="#0047AB" d="M11.77 20v-1h6.615q.25 0 .432-.154q.183-.154.183-.404v-6.888q0-2.81-2.066-4.693Q14.867 4.977 12 4.977T7.066 6.861Q5 8.744 5 11.554v5.677h-.5q-.613 0-1.057-.415Q3 16.402 3 15.79v-1.885q0-.448.291-.795q.292-.348.709-.565l.017-1.229q.027-1.565.68-2.9t1.744-2.323t2.524-1.54T12 4t3.033.552t2.513 1.538t1.735 2.32t.702 2.9L20 12.513q.398.187.699.503q.301.315.301.757v2.166q0 .442-.301.757t-.699.502v1.244q0 .652-.472 1.105T18.385 20zm-2.385-6.461q-.31 0-.54-.211q-.23-.21-.23-.52t.23-.53t.54-.22t.539.22t.23.53t-.23.52t-.54.21m5.232 0q-.31 0-.54-.21t-.23-.52t.23-.53t.54-.22t.539.22t.23.53t-.23.52t-.54.21M6.718 11.95q-.136-2.246 1.447-3.829q1.582-1.583 3.886-1.583q1.936 0 3.432 1.163t1.826 3.055q-1.987-.025-3.688-1.014t-2.61-2.75q-.362 1.731-1.505 3.034q-1.144 1.303-2.788 1.924"/>
                                    </svg>

                                </div>

                            </div>
                            
                            {/*paragraphe*/}
                            <div className="col-12">
                                <p className="fs14 bold500">Nous proposons un <span className="textColor"> service après-vente</span> dans les deux semaines suivant la livraison, sous réserve que le problème soit dû à un défaut de tissu non imputable à notre fabrication. Notre objectif est de garantir votre satisfaction et de résoudre rapidement toute issue concernant vos achats.</p>
                            </div>

                        </div>

                    </div>

                    {/*Images*/}
                    <div className="col-12 col-md-6 col-lg-5 mt-5">

                        <div className="container mforvestenoir">

                            <img src={imgMain} alt="deux mains" className="img-fluidFlexibilite" />

                        </div>
                    
                    </div>

                </div>

            </div>

            
            {/*ICONE SAFE BACKGROUND en XXL*/}
            <div className="container d-none d-xxl-block">
                <div className="bgsav1">
                    <img src={bgbag} alt="sac" className="img-fluid"/>
                </div>
                <div className="bgsav2">
                    <img src={bgbag} alt="sac" className="img-fluid"/>
                </div>
            </div>

        </div>

    );

}

export default SectionSav;