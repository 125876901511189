import React, { useEffect } from "react";
import "./CatalogueTravail.css";
import imgTravail from "../../../Assets/ressources/sections/office.jpg";
import SectionEsthetique from "./SectionEsthetique/SectionEsthetique";
import SectionHotel from "./SectionHotel/SectionHotel";
import SectionOffice from "./SectionOffice/SectionOffice";
import SectionForce from "./SectionForce/SectionForce";
import SectionMedicale from "./SectionMedicale/SectionMedicale";

const CatalogueTravail = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(

        <div className="CatalogueTravail">

            {/*Image Entete*/}
            <div className="w-100">

                {/*IMAGE*/}
                <img src={imgTravail} alt="office" className="img-fluidCatalogue"/>
                
                {/*TEXTE*/}
                <p className="display-3 text-center text-white textDisplay container">Vêtements de travail</p>
                
            </div>

            {/*Liste des collections pour vêtements de travail */}
            <SectionMedicale></SectionMedicale>
            <SectionOffice></SectionOffice>
            <SectionHotel></SectionHotel>
            <SectionForce></SectionForce>
            <SectionEsthetique></SectionEsthetique>

        </div>

    );

}

export default CatalogueTravail;